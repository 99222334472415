import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const DeleteStream = ({
  usage,
  showDeleteStream,
  handleCloseDeleteStream,
  selectedStream,
  deleteStream,
  isDeleting,
}) => {
  return (
    <Modal
      show={showDeleteStream}
      centered
      onHide={handleCloseDeleteStream}
      backdrop={isDeleting ? 'static' : true}
      contentClassName="p-3 onboarding-form-modal"
    >
      <Modal.Header className="border-0 text-center w-100">
        <Modal.Title className="w-100 mt-5" style={{ fontSize: '1.5rem' }}>
          Delete {}
          {usage === 'livestream'
            ? selectedStream?.metadata.name
            : usage === 'transcoding'
            ? 'asset'
            : null}
          ?
        </Modal.Title>
        <Button
          variant="light"
          className="gray-circle-btn"
          onClick={handleCloseDeleteStream}
          disabled={isDeleting}
        >
          <img src="/assets/icons/close-icon.svg" alt="Close Icon" />
        </Button>
      </Modal.Header>
      <Modal.Footer className="justify-content-center onboarding-form-footer">
        <Button
          variant="light"
          onClick={handleCloseDeleteStream}
          disabled={isDeleting}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          disabled={isDeleting}
          onClick={() => {
            deleteStream(
              usage === 'livestream'
                ? selectedStream.stream_id
                : usage === 'transcoding'
                ? selectedStream.asset_id
                : null
            );
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
DeleteStream.propTypes = {
  usage: PropTypes.any,
  showDeleteStream: PropTypes.any,
  handleCloseDeleteStream: PropTypes.any,
  selectedStream: PropTypes.any,
  deleteStream: PropTypes.any,
  isDeleting: PropTypes.any,
};
export default DeleteStream;
