export const createStreamingApp = async (res, licenseId) => {
  const streamingAppData = {
    platform: 'streaming',
    name: 'streaming',
    identifier: 'streaming',
  };
  await res.create(`/api/licenses/${licenseId}/apps`, streamingAppData);
  const updatedLicenses = await res.fetch('/api/licenses');
  const selectedLicense = updatedLicenses.find(
    (license) => license.id === licenseId
  );
  const updatedApps = selectedLicense['registered_apps'];
  const streamApp = updatedApps.find((x) => x.platform === 'streaming');
  return streamApp;
};
