import { userToken } from './Storage';

class Restful {
  /**
   * construct a RESTful API wrapper
   *
   * @param {String} [baseUrl] (default='')
   * @param {Object} [headers] (default={})
   * @return {Restful}
   */
  constructor(baseUrl = process.env.REACT_APP_BACKEND_URL, headers = {}) {
    this.baseUrl = baseUrl;
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken(),

      ...headers,
    };
  }
  /**
   * fetch an expirable url resource
   *
   * @param {String} url
   * @return {JSON}
   */
  async fetch(url, params = {}) {
    let requestUrl = this.baseUrl + url;
    if (Object.keys(params).length !== 0 && params.constructor === Object) {
      requestUrl = this.baseUrl + url + '?' + new URLSearchParams(params);
    }

    return fetch(requestUrl, {
      method: 'GET',
      headers: this.headers,
    }).then(async (res) => {
      // handle empty content
      if (res.status === 204) {
        return res.text();
      }

      const json = await res.json();
      if (url === '/api/user') {
        window.Intercom('update', {
          app_id: 'hdsacrdm',
          veeplay_user: true,
          user_id: json.id,
          email: json.email,
          first_name: json.first_name,
          last_name: json.last_name,
          company_name: json.company,
        });
      }
      return json;
    });
  }
  /**
   * create a new document
   *
   * @param {String} url
   * @param {Dict} params
   * @return {JSON}
   */
  async create(url, params = {}, body = {}) {
    let requestUrl = this.baseUrl + url;
    if (Object.keys(params).length !== 0 && params.constructor === Object) {
      requestUrl = this.baseUrl + url + '?' + new URLSearchParams(params);
    }

    return fetch(requestUrl, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(body),
    });
  }

  /**
   * update
   *
   * @param {String} url
   * @param {Dict} params
   * @return {JSON}
   */
  async update(url, params = {}, body = {}) {
    let requestUrl = this.baseUrl + url;
    if (Object.keys(params).length !== 0 && params.constructor === Object) {
      requestUrl = this.baseUrl + url + '?' + new URLSearchParams(params);
    }

    return fetch(requestUrl, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify(body),
    });
  }

  async delete(url, id) {
    return fetch(this.baseUrl + url + '/' + id, {
      method: 'DELETE',
      headers: this.headers,
    });
  }

  async deleteWebhook(url, params) {
    const request = await fetch(
      this.baseUrl + url + '?' + new URLSearchParams(params),
      {
        method: 'DELETE',
        headers: this.headers,
      }
    );
    return await request.json();
  }
}
export default Restful;
