/**
 * read a session data value
 *
 * @param {String} key
 * @return {Mixed} value
 */
export const read = (key) => {
  if (!sessionStorage.getItem(key)) return null;
  const value = sessionStorage.getItem(key);
  return JSON.parse(value);
};
/**
 * write a session data value
 *
 * @param {String} key
 * @param {Mixed} value
 */
export const write = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};
// reset all session data
export const reset = () => {
  sessionStorage.clear();
};
/**
 * removes a session data value
 *
 * @param {String} key
 * @return {Mixed} value
 */
export const remove = (key) => {
  sessionStorage.removeItem(key);
  return true;
};

export const userToken = () => {
  const token = sessionStorage.getItem('userToken');
  return JSON.parse(token);
};

export const userSelectedLicenseID = () => {
  const license = sessionStorage.getItem('userSelectedLicenseID');
  return JSON.parse(license);
};
