import React from 'react';
import { Button, Row, Col, Container } from 'react-bootstrap';
import {
  FaAndroid,
  IoLogoJavascript,
  GrApple,
  MdEdit,
  MdDelete,
} from 'react-icons/all';
import PropTypes from 'prop-types';

const RegisteredApp = ({
  app,
  handleShowshowEditApp,
  handleShowshowDeleteApp,
}) => {
  return (
    <Container className="pb-4">
      <Row>
        <Col className="col-md-auto registered-apps-border rounded-left">
          <Row
            className="align-items-center justify-content-center"
            style={{
              width: '50px',
              height: '50px',
            }}
          >
            <Col className="mx-auto">
              {app.platform === 'ios' ? (
                <GrApple size={26} color="#717171" />
              ) : app.platform === 'android' ? (
                <FaAndroid size={26} color="#3DDB85" />
              ) : (
                app.platform === 'web' && (
                  <IoLogoJavascript size={26} color="#FFB800" />
                )
              )}
            </Col>
          </Row>
        </Col>
        <Col className="registered-apps-border border-left-0 flex-grow-1 align-items-center d-flex">
          <b>{app.name}</b>
        </Col>
        <Col className="registered-apps-border border-left-0 flex-grow-1 align-items-center d-flex">
          {app.identifier}
        </Col>
        <Col className="col-md-auto registered-apps-border border-left-0 p-0">
          <Button
            className="mx-auto btn-reg-app w-100"
            variant="transparent"
            style={{ height: '50px' }}
            onClick={() => handleShowshowEditApp(app)}
          >
            <MdEdit size={26} color="#ACACAC" />
          </Button>
        </Col>
        <Col className="col-md-auto registered-apps-border border-left-0 rounded-right p-0">
          <Button
            className="mx-auto btn-reg-app"
            variant="transparent"
            style={{ width: '50px', height: '50px' }}
            onClick={() => handleShowshowDeleteApp(app)}
          >
            <MdDelete size={26} color="#ACACAC" />
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
RegisteredApp.propTypes = {
  handleShowshowEditApp: PropTypes.any,
  handleShowshowDeleteApp: PropTypes.any,
  app: PropTypes.any,
};
export default RegisteredApp;
