import React, { useEffect, useState, useRef } from 'react';
import { HiChevronDown } from 'react-icons/all';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-date-range';

const MockDropdown = ({
  usage,
  verticalHr,
  options,
  dropdownOption,
  setDropdownOption,
  otherInputValue,
  setOtherInputValue,
  otherInputValuePlaceholder,
  interval,
  setInterval,
  formatDate,
}) => {
  const [mockDropdownClicked, setMockDropdownClicked] = useState(false);
  const [divChevronClicked, setDivChevronClicked] = useState(false);
  const [changeBorder, setChangeBorder] = useState(
    '1px solid rgba(204, 204, 204, 1)'
  );
  const [showBorderOnClick, setShowBorderOnClick] = useState(
    '1px solid rgba(255, 255, 255, 0)'
  );
  const [inputMarginTop, setInputMarginTop] = useState('0px');
  const [inputMarginLeft, setInputMarginLeft] = useState('8px');
  const [divChevronMarginTop, setDivChevronMarginTop] = useState('0px');
  const [divChevronMarginRight, setDivChevronMarginRight] = useState('0px');
  const [divChevronWidth, setDivChevronWidth] = useState('38px');
  const [chevronDownColor, setChevronDownColor] = useState('#cccccc');
  const [chevronDownColorFocused, setChevronDownColorFocused] =
    useState('#595959');
  const [showMenu, setShowMenu] = useState(false);
  const [divOptionBg, setDivOptionBg] = useState('white');
  const [showOtherOptInput, setShowOtherOptInput] = useState(false);
  const [optionValue, setOptionValue] = useState('Select...');

  const handleShowMenu = () => {
    setMockDropdownClicked(true);
    setChevronDownColor('#595959');
    setChangeBorder('1px solid rgba(204, 204, 204, 0)');
    setShowBorderOnClick('2px solid rgba(0, 149, 255, 1)');
    setInputMarginTop('-2px');
    setInputMarginLeft('7px');
    setDivChevronMarginTop('-1px');
    setDivChevronMarginRight('-1px');
    setDivChevronWidth('37px');
    setShowMenu(!showMenu);
  };
  const handleDivChevronClick = () => {
    setDivChevronClicked(true);
    setChevronDownColor('#595959');
    setChevronDownColorFocused('#333333');
  };
  const changeBorderColor = () =>
    setChangeBorder('1px solid rgba(179, 179, 179, 1)');
  const revertBorderColor = () =>
    setChangeBorder('1px solid rgba(204, 204, 204, 1)');
  const changeChevronColorOnMouseEnter = () => setChevronDownColor('#8c8c8c');
  const revertChevronColorOnMouseLeave = () => setChevronDownColor('#cccccc');
  const changeChevronColorIfClicked = () =>
    setChevronDownColorFocused('#333333');
  const revertChevronColorIfClicked = () =>
    setChevronDownColorFocused('#595959');
  const handleMouseDownOption = (opt, e) => {
    e.preventDefault();
    setDivOptionBg('#badaf7');
    setDropdownOption(opt);
    setOptionValue(opt);
    if (opt === 'Other') {
      setShowOtherOptInput(true);
      setShowMenu(true);
    } else {
      setOtherInputValue('');
      setShowOtherOptInput(false);
      setShowMenu(false);
    }
  };
  const handleMouseUpOption = () => {
    setDivOptionBg('white');
  };
  function useOutsideClickEvent(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(false);
          setMockDropdownClicked(false);
          setDivChevronClicked(false);
          setChangeBorder('1px solid rgba(204, 204, 204, 1)');
          setShowBorderOnClick('1px solid rgba(255, 255, 255, 0)');
          setInputMarginTop('0px');
          setInputMarginLeft('8px');
          setDivChevronMarginTop('0px');
          setDivChevronMarginRight('0px');
          setChevronDownColor('#cccccc');
          setChevronDownColorFocused('#cccccc');
        }
      }
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideClickEvent(wrapperRef);
  const menuRef = useRef(null);
  useEffect(() => {
    if (menuRef.current) {
      menuRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [showMenu]);
  return (
    <div ref={wrapperRef}>
      <div
        className="mock-dropdown-container"
        style={{
          border: showBorderOnClick,
        }}
      >
        <div
          className="mock-dropdown"
          style={{
            border: changeBorder,
          }}
          onClick={handleShowMenu}
          onMouseEnter={mockDropdownClicked === true ? null : changeBorderColor}
          onMouseLeave={mockDropdownClicked === true ? null : revertBorderColor}
        >
          <input
            className="dropdown-input"
            value={
              usage === 'select-options'
                ? dropdownOption === 'Other'
                  ? otherInputValue
                  : optionValue
                : usage === 'date-range-picker'
                ? formatDate(interval[0].startDate, interval[0].endDate)
                : null
            }
            disabled
            type="text"
            readOnly="readonly"
            style={{
              marginTop: inputMarginTop,
              marginLeft: inputMarginLeft,
              color:
                usage === 'select-options'
                  ? optionValue === 'Select...'
                    ? '#808080'
                    : 'black'
                  : null,
            }}
          />
          <div
            style={{
              width: divChevronWidth,
              borderRadius:
                usage === 'select-options'
                  ? '0px 4px 4px 0px'
                  : usage === 'date-range-picker'
                  ? '4px'
                  : null,
              marginTop: divChevronMarginTop,
              marginRight: divChevronMarginRight,
            }}
            onMouseEnter={
              mockDropdownClicked === true
                ? changeChevronColorIfClicked
                : changeChevronColorOnMouseEnter
            }
            onMouseLeave={
              mockDropdownClicked === true
                ? revertChevronColorIfClicked
                : revertChevronColorOnMouseLeave
            }
            onClick={handleDivChevronClick}
          >
            <HiChevronDown
              className="hi-chevron-icon"
              size={22}
              style={{
                color:
                  divChevronClicked === true
                    ? chevronDownColorFocused
                    : chevronDownColor,
              }}
            />
            {verticalHr === true ? (
              <div
                style={{
                  width: '37px',
                  height: '19px',
                  borderLeft: '1px solid #cccccc',
                  marginTop: '-21px',
                }}
              />
            ) : null}
          </div>
        </div>
        {showMenu ? (
          <div
            ref={menuRef}
            className={
              usage === 'select-options'
                ? 'div-dropdown-options'
                : usage === 'date-range-picker'
                ? 'div-date-range-picker'
                : null
            }
          >
            {usage === 'select-options' ? (
              options.map((x) => (
                <div
                  key={x.value}
                  className="div-option"
                  onMouseDown={(e) => handleMouseDownOption(x.value, e)}
                  onMouseUp={() => handleMouseUpOption()}
                  style={{
                    background: x.value === dropdownOption ? divOptionBg : null,
                  }}
                >
                  <div className="form-check">
                    <div className="d-flex flex-row align-items-center option-label">
                      <div
                        className={
                          x.value === dropdownOption
                            ? 'dot-active d-flex justify-content-center align-items-center'
                            : 'dot d-flex justify-content-center align-items-center'
                        }
                        style={{ marginRight: '8px' }}
                      >
                        <div
                          className={
                            x.value === dropdownOption
                              ? 'small-dot-active'
                              : 'small-dot'
                          }
                        />
                      </div>
                      {x.label}
                    </div>
                  </div>
                </div>
              ))
            ) : usage === 'date-range-picker' ? (
              <DateRangePicker
                ranges={interval}
                onChange={(item) => {
                  setInterval([item.selection]);
                }}
              />
            ) : null}
            {showOtherOptInput ? (
              <div className="search-wrapper d-flex justify-content-center m-2">
                <input
                  placeholder={otherInputValuePlaceholder}
                  className="w-100 ml-5 p-1 other-input-onboarding"
                  value={otherInputValue}
                  onChange={(e) => setOtherInputValue(e.target.value)}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};
MockDropdown.propTypes = {
  usage: PropTypes.any,
  verticalHr: PropTypes.any,
  options: PropTypes.any,
  dropdownOption: PropTypes.any,
  setDropdownOption: PropTypes.any,
  otherInputValue: PropTypes.any,
  setOtherInputValue: PropTypes.any,
  otherInputValuePlaceholder: PropTypes.any,
  interval: PropTypes.any,
  setInterval: PropTypes.any,
  formatDate: PropTypes.any,
};
export default MockDropdown;
