import React, { useEffect } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

const AddService = ({
  isEdit,
  showAddService,
  handleCloseAddService,
  handleSubmitAddService,
  selectedService,
  handleServiceChange,
  enableOtherInputField,
  otherService,
  setOtherService,
  streamURL,
  setStreamURL,
  streamKey,
  setStreamKey,
  enableDoneBtn3rdParty,
  setEnableDoneBtn3rdParty,
}) => {
  const addServiceOptions = [
    {
      value: 'Youtube',
      label: 'Youtube',
      icon: '/assets/icons/youtube-icon.svg',
    },
    {
      value: 'Twitch',
      label: 'Twitch',
      icon: '/assets/icons/twitch-icon.svg',
    },
    {
      value: 'Other',
      label: 'Other',
      icon: null,
    },
  ];
  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      {props.data.value === 'Other' ? (
        ''
      ) : (
        <img
          src={props.data.icon}
          style={{ marginRight: '13px' }}
          alt={props.data.label}
        />
      )}
      {props.data.label}
    </Option>
  );
  useEffect(() => {
    if (selectedService !== 'Other') {
      if (selectedService.length > 0 && streamURL !== '' && streamKey !== '') {
        setEnableDoneBtn3rdParty(false);
      } else {
        setEnableDoneBtn3rdParty(true);
      }
    } else if (selectedService === 'Other') {
      if (otherService.length > 0 && streamURL !== '' && streamKey !== '') {
        setEnableDoneBtn3rdParty(false);
      } else {
        setEnableDoneBtn3rdParty(true);
      }
    } else {
      setEnableDoneBtn3rdParty(true);
    }
  }, [
    selectedService,
    streamURL,
    streamKey,
    setEnableDoneBtn3rdParty,
    otherService,
  ]);

  const dropdownWithIcon = ({ icon, label }) => (
    <div className="d-flex justify-content-between align-items-center">
      <div>
        {icon && <img src={icon} alt="icon" />}
        <label className="pl-3 mb-0" htmlFor="flexCheckDefault">
          {label}
        </label>
      </div>
    </div>
  );
  return (
    <Modal
      show={showAddService}
      onHide={handleCloseAddService}
      contentClassName="modal-add-service"
      centered
    >
      <Modal.Header style={{ border: 'none', padding: '32px 32px 0px 32px' }}>
        <Modal.Title>
          {Number.isInteger(isEdit) ? 'Edit' : 'Add'} Third party service
        </Modal.Title>
        <Button
          variant="light"
          className="gray-circle-btn"
          style={{ marginTop: '-0.4em', marginRight: '-0.5em' }}
          onClick={handleCloseAddService}
        >
          <img src="/assets/icons/close-icon.svg" alt="Close Icon" />
        </Button>
      </Modal.Header>
      <Modal.Body style={{ padding: '22px 32px 0px 32px' }}>
        <Row>
          <Col md={6} style={{ marginTop: '10px' }}>
            <p className="add-service-modal-label">
              Service<span className="blue-asterisk"> *</span>
            </p>
            <Select
              options={addServiceOptions}
              components={{ Option: IconOption }}
              formatOptionLabel={dropdownWithIcon}
              isSearchable={false}
              isClearable={false}
              value={addServiceOptions.find(
                (obj) => obj.value === selectedService
              )}
              onChange={(e) => handleServiceChange(e)}
            />
          </Col>
          <Col md={6} style={{ marginTop: '10px' }}>
            <p className="add-service-modal-label">Other</p>
            <input
              className={
                enableOtherInputField
                  ? 'other-service-input-disabled'
                  : 'other-service-input'
              }
              placeholder="Enter service name"
              disabled={enableOtherInputField}
              value={otherService}
              onChange={(e) => setOtherService(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <div>
              <p className="add-service-modal-label">
                Stream URL<span className="blue-asterisk"> *</span>
              </p>
              <input
                className="stream-url-input"
                placeholder="Enter stream URL"
                value={streamURL}
                onChange={(e) => setStreamURL(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <div>
              <p className="add-service-modal-label">
                Stream Key<span className="blue-asterisk"> *</span>
              </p>
              <input
                className="stream-url-input"
                placeholder="Enter stream key"
                value={streamKey}
                onChange={(e) => setStreamKey(e.target.value)}
              />
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer
        style={{ border: 'none', paddingRight: '28px', paddingLeft: '28px' }}
      >
        <div className="d-md-flex justify-content-md-end align-items-center">
          <Button
            className="btn-cancel"
            onClick={handleCloseAddService}
            style={{ padding: '10px 21px' }}
          >
            Cancel
          </Button>
          <Button
            className="btn-done"
            style={{ padding: '10px 25.5px' }}
            disabled={enableDoneBtn3rdParty}
            onClick={handleSubmitAddService}
          >
            Done
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
AddService.propTypes = {
  isEdit: PropTypes.any,
  showAddService: PropTypes.any,
  handleCloseAddService: PropTypes.any,
  data: PropTypes.any,
  handleSubmitAddService: PropTypes.any,
  selectedService: PropTypes.any,
  handleServiceChange: PropTypes.any,
  enableOtherInputField: PropTypes.any,
  otherService: PropTypes.any,
  setOtherService: PropTypes.any,
  streamURL: PropTypes.any,
  setStreamURL: PropTypes.any,
  streamKey: PropTypes.any,
  setStreamKey: PropTypes.any,
  enableDoneBtn3rdParty: PropTypes.any,
  setEnableDoneBtn3rdParty: PropTypes.any,
};
export default AddService;
