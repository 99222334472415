import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  ToggleButton,
  ToggleButtonGroup,
  Modal,
  Form,
} from 'react-bootstrap';
import VideoCard from '../components/VideoCard';
import Section from '../components/Section';
import { useMutation, useQuery } from 'react-query';
import Restful from '../utils/API';
import { FaChevronLeft, FaChevronRight } from 'react-icons/all';
import { write, userSelectedLicenseID } from '../utils/Storage';
import { useForm } from 'react-hook-form';
import useInterval from '../utils/polling';
import _ from 'lodash';
import { createStreamingApp } from '../utils/stream';
import DeleteStream from '../components/DeleteStream';
const Transcoding = () => {
  const description =
    'The Veeplay Video API makes media transcoding and cross-device delivery easy for developers looking to integrate video into their apps.';

  const [res, setRes] = useState(
    new Restful(process.env.REACT_APP_BACKEND_URL)
  );
  const [assetList, setAssetList] = useState([]);
  const [pageSize, setPageSize] = useState(3);
  const [totalPages, setTotalPages] = useState([1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [licenses, setLicenses] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [isAddingAsset, setIsAddingAsset] = useState(false);
  const [showDeleteVideoModal, setShowDeleteVideoModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState();
  const pollStatuses = ['CREATED', 'QUEUED', 'SUBMITTED', 'PROGRESSING'];
  const fetchLicenses = async () => {
    return res.fetch('/api/licenses');
  };

  const { data: licensesData } = useQuery('licensesList', fetchLicenses);

  useEffect(() => {
    (async (data) => {
      if (!data) {
        return;
      }
      const statefulLicense = userSelectedLicenseID();
      let license = data.find((license) => license.id === statefulLicense);
      if (!license || license.license_type_id === 4) {
        const excludedTrialLicense = data.filter(function (item) {
          return item.license_type_id !== 4;
        });
        if (excludedTrialLicense.length > 0) {
          license = excludedTrialLicense[0];
        } else {
          license = data[0];
        }
      }
      let streamApp = license['registered_apps'].find(
        (x) => x.platform === 'streaming'
      );

      if (streamApp === undefined) {
        streamApp = await createStreamingApp(res, license.id);
      }

      const header = { 'X-Api-Key': streamApp.api_keys[0].key };
      setRes(new Restful(process.env.REACT_APP_BACKEND_URL, header));

      const mappedLicenses = data.map((license) => ({
        label: license.license_type.name,
        license_type_id: license.license_type_id,
        value: license.id,
        info: {
          code: license.code,
          valid_until: license.valid_until,
          appsLength: license.registered_apps.length,
          platforms: [
            ...new Set(license.registered_apps.map((app) => app.platform)),
          ],
        },
        apps: license['registered_apps'],
      }));
      setLicenses(mappedLicenses);
      let selectedLicense = mappedLicenses.find(
        (license) => license.license_type_id !== 4
      );

      if (selectedLicense === undefined) {
        selectedLicense = mappedLicenses[0];
      }

      write('userSelectedLicenseID', selectedLicense.value);

      setSelectedLicense(selectedLicense);
    })(licensesData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licensesData]);

  const fetchAssets = async () => {
    const resData = await res.fetch('/stream/assets');
    setAssetList(resData);
  };

  function getAsset(id) {
    const url = `/stream/assets/${id}`;
    return new Promise((resolve, reject) => {
      res.fetch(url).then((data) => {
        resolve(data);
      });
    });
  }

  useInterval(() => {
    if (
      assetList.length > 0 &&
      assetList.find((asset) => pollStatuses.includes(asset.status))
    ) {
      const toPollList = assetList.filter((asset) =>
        pollStatuses.includes(asset.status)
      );
      const assetRequests = [];
      toPollList.forEach((asset) =>
        assetRequests.push(getAsset(asset.asset_id))
      );

      Promise.all(assetRequests).then((allAssetData) => {
        if (!_.isEqual(allAssetData, assetList)) {
          // find and replace updated asset, and update list
          const updatedAssetList = [...assetList];
          allAssetData.forEach((asset) => {
            const outdatedAssetIndex = updatedAssetList.findIndex(
              (outDatedAsset) => outDatedAsset.asset_id === asset.asset_id
            );
            if (!_.isEqual(asset, assetList[outdatedAssetIndex])) {
              updatedAssetList[outdatedAssetIndex] = asset;
            }
          });

          if (!_.isEqual(updatedAssetList, assetList)) {
            setAssetList(updatedAssetList);
          }
          // setAssetList(allAssetData);
        }
      });
    }
  }, 5000);

  const {
    isSuccess: getAssetList,
    isLoading: isLoadingAssets,
    refetch: refetchAssets,
  } = useQuery('assetsList', fetchAssets, {
    enabled: selectedLicense != null,
  });

  useEffect(() => {
    const numberPages = Math.ceil(assetList.length / pageSize);
    setTotalPages([...Array(numberPages).keys()].map((a) => a + 1));
    setCurrentPage(1);
  }, [assetList, pageSize]);

  const handleLicenseChange = (e) => {
    // write('userSelectedLicenseID', e.value);
    const selLicense = licenses.find((license) => license.value === e.value);
    const selLicenseStreamApp = selLicense.apps.find(
      (app) => app.platform === 'streaming'
    );
    if (selLicenseStreamApp !== undefined) {
      const header = { 'X-Api-Key': selLicenseStreamApp.api_keys[0].key };
      setRes(new Restful(process.env.REACT_APP_BACKEND_URL, header));
    }
    setSelectedLicense(selLicense);
  };

  useEffect(() => {
    if (selectedLicense) {
      refetchAssets();
    }
  }, [res, refetchAssets, selectedLicense]);

  const { register, handleSubmit, reset } = useForm();
  const postAsset = async (data) => {
    setIsAddingAsset(true);
    const postData = {
      input_url: data.input_url,
      normalize_audio: true,
      metadata: {
        via_dashboard: true,
      },
    };
    return await res.create(`/stream/assets`, {}, postData);
  };

  const { mutate } = useMutation(postAsset, {
    onSuccess: () => {
      refetchAssets();
    },
  });

  const onSubmit = (data) => {
    mutate(data);
    reset();
    setIsAddingAsset(false);
    setShowCreateAssetsModal(false);
  };

  const deleteAsset = async (asset_id) => {
    setIsDeleting(true);
    return res.delete(`/stream/assets`, asset_id);
  };

  const { mutate: mutateDeleteAsset } = useMutation(deleteAsset, {
    onSuccess: () => {
      setIsDeleting(false);
      setShowDeleteVideoModal(false);
      refetchAssets();
    },
  });

  const [showCreateAssetsModal, setShowCreateAssetsModal] = useState(false);
  const handleCloseCreateAssetModal = () => setShowCreateAssetsModal(false);
  const openCreateAssetModal = () => {
    setIsAddingAsset(false);
    setShowCreateAssetsModal(true);
  };

  function handleShowDeleteAsset(asset) {
    setSelectedAsset(asset);
    setShowDeleteVideoModal(true);
  }

  return (
    <Container className="mt-5">
      <DeleteStream
        usage={'transcoding'}
        showDeleteStream={showDeleteVideoModal}
        handleCloseDeleteStream={() => setShowDeleteVideoModal(false)}
        selectedStream={selectedAsset}
        deleteStream={mutateDeleteAsset}
        isDeleting={isDeleting}
      />
      <Section
        title="Transcoding"
        description={description}
        licenses={licenses}
        selectedLicense={selectedLicense}
        handleLicenseChange={handleLicenseChange}
      />
      <Row className="mt-4">
        <Col md={12}>
          <hr style={{ background: '#D1D1D1' }}></hr>
        </Col>
      </Row>
      <Row className="mt-4 align-items-center">
        <Col xs={6}>
          <h3 className="heading-h3 m-0">Video Assets ({assetList.length})</h3>
        </Col>
        <Col xs={6}>
          <div className="d-flex justify-content-md-end">
            <Button
              variant="primary"
              className="btn-video-asset"
              onClick={openCreateAssetModal}
            >
              Add Video Asset
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        {isLoadingAssets ? (
          <Col>
            <div className="no-livestream w-100 mt-5">Loading</div>
          </Col>
        ) : (
          <>
            {getAssetList && assetList.length > 0 ? (
              assetList
                .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                .map((item, idx) => (
                  <Col md={6} key={`asset-${idx}`} className="mt-5">
                    <VideoCard
                      item={item}
                      handleShowDeleteAsset={handleShowDeleteAsset}
                    />
                  </Col>
                ))
            ) : (
              <Col>
                <div className="no-livestream w-100 mt-5">
                  No video assets added yet.
                </div>
              </Col>
            )}
          </>
        )}
      </Row>
      {totalPages.length >= 1 && (
        <Row className="mt-5">
          <Col md={6}>
            <div className="d-flex align-items-center">
              <div style={{ marginRight: '5px' }}>Videos / page</div>
              <DropdownButton
                id="dropdown-basic-button"
                variant="outline-secondary"
                title={pageSize}
                onSelect={(e) => setPageSize(e)}
              >
                <Dropdown.Item eventKey={1}>1</Dropdown.Item>
                <Dropdown.Item eventKey={3}>3</Dropdown.Item>
                <Dropdown.Item eventKey={5}>5</Dropdown.Item>
                <Dropdown.Item eventKey={10}>10</Dropdown.Item>
              </DropdownButton>
            </div>
          </Col>
          <Col md={6}>
            <div className="d-flex align-items-center justify-content-md-end">
              <ButtonGroup aria-label="Basic example">
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    const previous = currentPage - 1;
                    if (previous !== 0) {
                      setCurrentPage(previous);
                    }
                  }}
                >
                  <FaChevronLeft />
                </Button>
                <ToggleButtonGroup
                  type="radio"
                  name="page"
                  value={currentPage}
                  onChange={(e) => setCurrentPage(e)}
                >
                  {totalPages.map((item, idx) => (
                    <ToggleButton
                      key={idx}
                      variant="outline-secondary"
                      value={item}
                    >
                      {item}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>

                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    const next = Number(currentPage) + Number(1);
                    const lastpage = totalPages.slice(-1)[0];
                    if (next <= lastpage) {
                      setCurrentPage(next);
                    }
                  }}
                >
                  <FaChevronRight />
                </Button>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
      )}
      <Modal
        show={showCreateAssetsModal}
        onHide={handleCloseCreateAssetModal}
        centered
        contentClassName="onboarding-form-modal p-3"
        style={{ borderRadius: '12px' }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header className="border-0">
            <Modal.Title>
              <Container>
                <Row>
                  <Col>
                    <div className="mt-3" style={{ fontSize: '1.5rem' }}>
                      Add video asset
                    </div>
                  </Col>
                </Row>
              </Container>
            </Modal.Title>
            <Button
              variant="light"
              className="gray-circle-btn"
              onClick={handleCloseCreateAssetModal}
            >
              <img src="/assets/icons/close-icon.svg" alt="Close Icon" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Video URL *</Form.Label>
              <Form.Control
                type="text"
                className="w-100"
                placeholder="Enter URL"
                {...register('input_url', { required: true })}
              />
            </Form.Group>
            <div>
              <a
                style={{ color: '#4D4D4D' }}
                href="https://docs.veeplay.com/video-api/#section/Supported-Inputs"
                target="_blank"
                rel="noreferrer"
              >
                See supported input formats{' '}
                <img
                  src="/assets/icons/caret-right-icon.svg"
                  alt="Copy Icon"
                  className="copy-icon copy-icon-api ml-2"
                />
              </a>
            </div>
          </Modal.Body>
          <Modal.Footer className="onboarding-form-footer">
            <Button
              variant="light"
              onClick={handleCloseCreateAssetModal}
              disabled={isAddingAsset}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              as="input"
              type="submit"
              disabled={isAddingAsset}
              value={isAddingAsset ? 'Adding' : 'Add'}
            ></Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default Transcoding;
