/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */

// TODO: fix eslint warnings
import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  Modal,
  Spinner,
} from 'react-bootstrap';
import Section from '../components/Section';
import Toaster from '../components/Toaster';
import { FaCaretRight } from 'react-icons/all';
import Restful from '../utils/API';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import OnboardingForm from '../components/OnboardingForm';
import { write, userSelectedLicenseID } from '../utils/Storage';
import { createStreamingApp } from '../utils/stream';

const Videoapi = () => {
  const queryClient = useQueryClient();
  const [res, setRes] = useState(
    new Restful(process.env.REACT_APP_BACKEND_URL)
  );
  const [licenses, setLicenses] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState();

  const description =
    'The Veeplay Video API makes media transcoding and cross-device\n' +
    '          delivery easy for developers looking to integrate video into their\n' +
    '          apps. Simply provide a mezzanine input of your video and Veeplay will\n' +
    '          create HLS and DASH renderings ready to play on any device, delivered\n' +
    '          via a global CDN.';

  const fetchLicenses = async () => {
    return res.fetch('/api/licenses');
  };
  const fetchUser = async () => {
    return res.fetch('/api/user');
  };

  const { data: licensesData } = useQuery(
    'videoApisLicenseData',
    fetchLicenses
  );

  useEffect(() => {
    (async (data) => {
      if (!data) {
        return;
      }
      const statefulLicense = userSelectedLicenseID();
      let license = data.find((license) => license.id === statefulLicense);
      if (!license || license.license_type_id === 4) {
        const excludedTrialLicense = data.filter(function (item) {
          return item.license_type_id !== 4;
        });
        if (excludedTrialLicense.length > 0) {
          license = excludedTrialLicense[0];
        } else {
          license = data[0];
        }
      }
      const licenseId = license.id;
      let streamApp = license['registered_apps'].find(
        (x) => x.platform === 'streaming'
      );
      if (streamApp === undefined) {
        streamApp = await createStreamingApp(res, licenseId);
      }

      const header = { 'X-Api-Key': streamApp.api_keys[0].key };
      setRes(new Restful(process.env.REACT_APP_BACKEND_URL, header));

      const mappedLicenses = data.map((license) => ({
        label: license.license_type.name,
        license_type_id: license.license_type_id,
        value: license.id,
        info: {
          code: license.code,
          valid_until: license.valid_until,
          appsLength: license.registered_apps.length,
          platforms: [
            ...new Set(license.registered_apps.map((app) => app.platform)),
          ],
        },
        apps: license['registered_apps'],
      }));
      setLicenses(mappedLicenses);
      let selLicense = mappedLicenses.find(
        (license) => license.license_type_id !== 4
      );

      if (selLicense === undefined) {
        selLicense = mappedLicenses[0];
      }

      write('userSelectedLicenseID', selLicense.value);

      setSelectedLicense(selLicense);
    })(licensesData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licensesData]);

  const fetchStreamApp = async () => {
    const data = await res.fetch('/api/licenses');
    const selLicense = data.find(
      (license) => license.id === selectedLicense.value
    );
    const streamApp = selLicense['registered_apps'].find(
      (x) => x.platform === 'streaming'
    );
    return streamApp;
  };

  const {
    data: selectedLicenseStreamApp,
    refetch: refetchSelectedLicenseStreamApp,
  } = useQuery('selectedLicenseStreamApp', fetchStreamApp, {
    enabled: selectedLicense != null,
  });

  const { data: userData, isSuccess: userDataSuccess } = useQuery(
    'userInformation',
    fetchUser
  );

  const { register, handleSubmit, reset } = useForm();

  const postWebhook = async (data) => {
    return res.create(
      `/api/licenses/${data.licenseId}/apps/${data.appId}/webhooks`,
      data.data
    );
  };
  const deleteWebhook = async (data) => {
    return await res.deleteWebhook(
      `/api/licenses/${data.licenseId}/apps/${data.appId}/webhooks`
    );
  };

  const { mutate: mutateAddWebhook, isLoading: isAddingWebhook } = useMutation(
    postWebhook,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('selectedLicenseStreamApp');
      },
    }
  );

  const { mutate: mutateDeleteWebhook } = useMutation(deleteWebhook, {
    onSuccess: () => {
      queryClient.invalidateQueries('selectedLicenseStreamApp');
    },
  });

  const onSubmit = (data) => {
    mutateAddWebhook({
      licenseId: selectedLicense.value,
      appId: selectedLicenseStreamApp.id,
      data: data,
    });
    reset();
  };
  const [showSavedWebhooks, setShowSavedWebhooks] = useState(false);
  const handleShowSavedWebhooks = () => setShowSavedWebhooks(true);
  const handleCloseSavedWebhooks = () => setShowSavedWebhooks(false);

  const [showTooltipAPIKey, setShowTooltipAPIKey] = useState(false);
  const [showTooltipAPPID, setShowTooltipAPPID] = useState(false);
  const targetAPIKey = useRef(null);
  const targetAppID = useRef(null);
  const copyAPIKeyValue = () => {
    let api_key = '';
    if (selectedLicenseStreamApp) {
      api_key = selectedLicenseStreamApp.api_keys[0].key;
    }
    navigator.clipboard.writeText(api_key);
    setShowTooltipAPIKey(true);
    setTimeout(() => setShowTooltipAPIKey(false), 3000);
  };
  const copyAppIDValue = () => {
    let app_id = '';
    if (selectedLicenseStreamApp) {
      app_id = selectedLicenseStreamApp.hash_id;
    }
    navigator.clipboard.writeText(app_id);
    setShowTooltipAPPID(true);
    setTimeout(() => setShowTooltipAPPID(false), 3000);
  };

  const handleLicenseChange = (e) => {
    const selLicense = licenses.find((license) => license.value === e.value);
    const selLicenseStreamApp = selLicense['apps'].find(
      (x) => x.platform === 'streaming'
    );

    if (selLicenseStreamApp !== undefined) {
      const header = { 'X-Api-Key': selLicenseStreamApp.api_keys[0].key };
      setRes(new Restful(process.env.REACT_APP_BACKEND_URL, header));
    }
    setSelectedLicense(selLicense);
  };

  useEffect(() => {
    if (selectedLicense) {
      refetchSelectedLicenseStreamApp();
    }
  }, [res, refetchSelectedLicenseStreamApp, selectedLicense]);

  return (
    <Container className="mt-5">
      <Section
        title="Video APIs"
        description={description}
        licenses={licenses}
        selectedLicense={selectedLicense}
        handleLicenseChange={handleLicenseChange}
      />
      <Row className="mt-5 mb-5">
        <Col sm={12} md={6}>
          <img
            className="w-100"
            src="/assets/video-api-computer.svg"
            alt="Computer Graphic"
          />
        </Col>
        <Col md={6} className="align-self-center mt-3 mt-md-0">
          <h6 style={{ fontWeight: 'bold' }}>DOCS</h6>
          <p>
            Read our guide on video streaming  for a visually illustrated
            explanation of automating your video processing workflow with the
            Veeplay API.
          </p>
          <a
            rel="noreferrer"
            className="btn btn-secondary-outline btn-secondary-hover"
            href="https://docs.veeplay.com/video-api/"
            target="_blank"
          >
            Video APIs Docs
          </a>
        </Col>
      </Row>
      <Row className="mt-5 align-items-center">
        <Col xs={6}>
          <h4 style={{ fontWeight: 'bold', margin: '0' }}>
            API Keys & Webhooks
          </h4>
        </Col>
        <Col xs={6}>
          <div className="d-flex justify-content-md-end">
            <a
              rel="noreferrer"
              className="btn btn-secondary-outline btn-secondary-hover"
              href="https://docs.veeplay.com/video-api/#section/Webhooks"
              target="_blank"
            >
              Webhook Docs
            </a>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center mt-4">
        <Col sm={12} md={6}>
          <Row className="align-items-center">
            <Col xs={12} md={2}>
              <div className="video-api-label">API Key</div>
            </Col>
            <Col xs={12} md={10}>
              <Row className="align-items-center">
                <Col xs={10} md={11}>
                  <div className="px-3 py-1 video-api-key-value">
                    {selectedLicenseStreamApp &&
                      `${selectedLicenseStreamApp.api_keys[0].key}`}
                  </div>
                </Col>
                <Col xs={2} md={1}>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="gray-circle-btn"
                      variant="light"
                      ref={targetAPIKey}
                      onClick={copyAPIKeyValue}
                    >
                      <img src="/assets/icons/Copy.svg" alt="Copy Icon" />
                    </Button>
                    <Toaster
                      target={targetAPIKey.current}
                      showTooltip={showTooltipAPIKey}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={6}>
          <Row className="align-items-center">
            <Col sm={12} md={2}>
              <div className="video-api-label">APP ID</div>
            </Col>
            <Col sm={12} md={10}>
              <Row className="align-items-center">
                <Col xs={10} md={11}>
                  <div className="px-3 py-1 video-api-key-value">
                    {selectedLicenseStreamApp &&
                      `${selectedLicenseStreamApp.hash_id}`}
                  </div>
                </Col>
                <Col xs={2} md={1}>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="gray-circle-btn"
                      variant="light"
                      ref={targetAppID}
                      onClick={copyAppIDValue}
                    >
                      <img src="/assets/icons/Copy.svg" alt="Copy Icon" />
                    </Button>
                    <Toaster
                      target={targetAppID.current}
                      showTooltip={showTooltipAPPID}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mt-5">
          <Col sm={12} md={6}>
            <Row className="align-items-center">
              <Col xs={12} md={2}>
                <div className="video-api-label">Webhook</div>
              </Col>
              <Col xs={9} md={8}>
                <input
                  type="text"
                  className="w-100 video-api-new-webhook-input"
                  placeholder="Enter webhook name"
                  {...register('url', { required: true })}
                />
              </Col>
              <Col xs={3} md={2}>
                <Button
                  variant="outline-secondary"
                  className="align-self-md-end btn btn-secondary-outline btn-secondary-hover"
                  type="submit"
                  style={{ minWidth: '60px' }}
                >
                  {isAddingWebhook ? (
                    <Spinner
                      animation="border"
                      variant={'light'}
                      role="status"
                      size={'sm'}
                    ></Spinner>
                  ) : (
                    'Save'
                  )}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={6} className="d-flex justify-content-md-end">
            <Button
              variant="link"
              style={{ color: '#4D4D4D' }}
              onClick={handleShowSavedWebhooks}
            >
              {selectedLicenseStreamApp &&
                `Saved Webhooks (${selectedLicenseStreamApp.webhook_endpoints.length})`}{' '}
              <span>
                <FaCaretRight />
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
      {selectedLicenseStreamApp && (
        <Modal
          show={showSavedWebhooks}
          onHide={handleCloseSavedWebhooks}
          centered
          contentClassName="saved-webhooks-modal"
          style={{ borderRadius: '12px' }}
        >
          <Modal.Header className="px-0 flex-column align-items-center pt-0">
            <Button
              variant="light"
              className="gray-circle-btn align-self-end p-0"
              onClick={handleCloseSavedWebhooks}
            >
              <img src="/assets/icons/close-icon.svg" alt="Close Icon" />
            </Button>
            <Modal.Title className="align-self-start">{`Saved Webhooks (${selectedLicenseStreamApp.webhook_endpoints.length})`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column">
              {selectedLicenseStreamApp.webhook_endpoints.length === 0 && (
                <div className="d-flex justify-content-between my-3">
                  <div>No webhook found.</div>
                </div>
              )}
              {selectedLicenseStreamApp.webhook_endpoints.map(
                (webhook, idx) => (
                  <div
                    className="d-flex justify-content-between my-3"
                    key={idx}
                  >
                    <div>{`${webhook.event} - ${webhook.target_url}`}</div>
                    <Button
                      variant="light"
                      className="float-right gray-circle-btn"
                      onClick={() =>
                        mutateDeleteWebhook({
                          licenseId: selectedLicense.value,
                          appId: selectedLicenseStreamApp.id,
                        })
                      }
                    >
                      <img
                        src="/assets/icons/trash-icon.svg"
                        alt="Trash icon"
                      />
                    </Button>
                  </div>
                )
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseSavedWebhooks}>
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {userDataSuccess && <OnboardingForm userData={userData} />}
    </Container>
  );
};

export default Videoapi;
