import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      © 2021 veeplay.com - Appscend Video Solutions GmbH
    </footer>
  );
};

export default Footer;
