/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

// TODO: fix eslint warnings
import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Form, ButtonGroup, Modal } from 'react-bootstrap';
import Select from 'react-select';
import Restful from '../utils/API';
import { useMutation } from 'react-query';
import PropTypes from 'prop-types';
import {
  BiCodeCurly,
  DiJavascript1,
  FaAndroid,
  FaApple,
} from 'react-icons/all';
import MockDropdown from './MockDropdown';

const OnboardingForm = (userData) => {
  const res = new Restful(process.env.REACT_APP_BACKEND_URL);
  const [showOnboardingForm, setShowOnboardingForm] = useState(
    !userData.userData.intro_form_displayed
  );
  const handleCloseShowOnboardingForm = () => setShowOnboardingForm(false);
  const [showOnboardingRecommendation, setShowOnboardingRecommendation] =
    useState(!userData.userData.intro_recommendations_displayed);
  const handleCloseShowOnboardingRecommendation = () =>
    setShowOnboardingRecommendation(false);

  const [questionOneValue, setQuestionOneValue] = useState('');

  const [questionTwoSelectedValues, setQuestionTwoSelectedValues] = useState(
    []
  );

  const [questionThreeValue, setQuestionThreeValue] = useState('');

  const [questionFourSelectedValues, setQuestionFourSelectedValues] = useState(
    []
  );

  const [canContinueOnboarding, setCanContinueOnboarding] = useState(true);

  const [questionOneOtherInputValue, setQuestionOneOtherInputValue] =
    useState('');
  const [questionThreeOtherInputValue, setQuestionThreeOtherInputValue] =
    useState('');

  useEffect(() => {
    if (
      questionOneValue !== '' &&
      questionThreeValue !== '' &&
      questionTwoSelectedValues.length > 0 &&
      questionFourSelectedValues.length > 0
    ) {
      if (questionOneValue === 'Other' && questionOneOtherInputValue === '') {
        setCanContinueOnboarding(true);
      } else if (
        questionThreeValue === 'Other' &&
        questionThreeOtherInputValue === ''
      ) {
        setCanContinueOnboarding(true);
      } else {
        setCanContinueOnboarding(false);
      }
    } else {
      setCanContinueOnboarding(true);
    }
  }, [
    questionOneValue,
    questionTwoSelectedValues,
    questionThreeValue,
    questionFourSelectedValues,
    questionOneOtherInputValue,
    questionThreeOtherInputValue,
  ]);

  const questionOneOptions = [
    { value: 'Developer', label: 'Developer' },
    { value: 'Product Owner', label: 'Product Owner' },
    { value: 'Tech Executive', label: 'Tech Executive' },
    { value: 'Other', label: 'Other' },
  ];

  const questionTwoOptions = [
    {
      icon: '/assets/icons/ios-icon.svg',
      value: 'iOS',
      label: 'iOS',
      url: 'https://docs.veeplay.com/ios-player/readme.html',
    },
    {
      icon: '/assets/icons/android-icon.svg',
      value: 'Android',
      label: 'Android',
      url: 'https://docs.veeplay.com/android-player/',
    },
    {
      icon: '/assets/icons/js-icon.svg',
      value: 'Web',
      label: 'Web',
      url: 'https://docs.veeplay.com/javascript-player/',
    },
    {
      icon: '/assets/icons/smart-tv-icon.svg',
      value: 'Smart TV',
      label: 'Smart TV',
      url: 'https://docs.veeplay.com/video-api/',
    },
  ];

  const questionThreeOptions = [
    { value: 'News/Media', label: 'News/Media' },
    { value: 'OTT/VOD', label: 'OTT/VOD' },
    { value: 'Live Events', label: 'Live Events' },
    { value: 'E-Commerce', label: 'E-Commerce' },
    { value: 'Ad-Tech', label: 'Ad-Tech' },
    { value: 'Other', label: 'Other' },
  ];

  const handleQuestionTwoChange = (e) => {
    const selectedValues = [];
    e.map((item) => {
      selectedValues.push({
        value: item.value,
        icon: item.icon,
        url: item.url,
      });
    });
    setQuestionTwoSelectedValues(selectedValues);
  };

  const handleQuestionFourChange = (e) => {
    const selectedValues = [];
    e.map((item) => {
      selectedValues.push({ value: item.value, url: item.url });
    });
    setQuestionFourSelectedValues(selectedValues);
  };

  const questionFourOptions = [
    {
      label: 'PLAYBACK / STREAMING',
      options: [
        {
          label: 'Video Players',
          value: 'Video Players',
          url: 'https://docs.veeplay.com/video-api/',
        },
        {
          label: 'Video File storage & management',
          value: 'Video File storage & management',
          url: 'https://docs.veeplay.com/video-api/',
        },
        {
          label: 'Live Streaming',
          value: 'Live Streaming',
          url: 'https://docs.veeplay.com/video-api/',
        },
        {
          label: 'Video Transcoding',
          value: 'Video Transcoding',
          url: 'https://docs.veeplay.com/video-api/',
        },
      ],
    },
    {
      label: 'VIDEO MONETIZATION',
      options: [
        {
          label: 'Server Side Ad Insertion',
          value: 'Server Side Ad Insertion',
          url: 'https://docs.veeplay.com/docs/ssai/index/',
        },
        {
          label: 'Ads Inventory',
          value: 'Ads Inventory',
          url: 'https://docs.veeplay.com/docs/ssai/index/',
        },
        {
          label: 'In-player Paywall',
          value: 'In-player Paywall',
          url: 'https://docs.veeplay.com/docs/ssai/index/',
        },
      ],
    },
  ];

  const checkboxWithIconQuestionOption = ({ icon, value, label }) => (
    <div className="d-flex justify-content-between align-items-center">
      <div>
        <img src={icon} />
        <label className="pl-3 mb-0" htmlFor="flexCheckDefault">
          {label}
        </label>
      </div>
      {/*<input type="checkbox" value="" id="flexCheckDefault" />*/}
    </div>
  );

  const checkboxQuestionOption = ({ icon, value, label }) => (
    <div className="d-flex justify-content-between align-items-center">
      <div>
        <label className="pl-3 mb-0" htmlFor="flexCheckDefault">
          {label}
        </label>
      </div>
      {/*<input type="checkbox" value="" id="flexCheckDefault" />*/}
    </div>
  );

  const colourStyles = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor: null,
      color: 'black',
    }),
  };

  const putQualifyUser = async (data) => {
    return await res.update(`/api/user/qualify`, data);
  };
  const putOnboardingFormDisplay = async () => {
    return await res.update(`/api/user/form-displayed`);
  };
  const putOnboardingRecommendationDisplay = async () => {
    return await res.update(`/api/user/recommendations-displayed`);
  };

  useEffect(() => {
    const platformInterests = userData.userData.platform_interest;
    const productInterests = userData.userData.product_interest;
    if (platformInterests != null) {
      const platforms = platformInterests.split(',');
      const selectedPlatforms = [];
      for (let i = 0; i < platforms.length; i++) {
        const platformOption = questionTwoOptions.find(
          (x) => x.value === platforms[i]
        );
        if (platformOption) selectedPlatforms.push(platformOption);
      }
      setQuestionTwoSelectedValues(selectedPlatforms);
    }
    if (productInterests != null) {
      const products = productInterests.split(',');
      const selectedProducts = [];
      for (let i = 0; i < products.length; i++) {
        const productOptionOne = questionFourOptions[0].options.find(
          (x) => x.value === products[i]
        );
        if (productOptionOne) selectedProducts.push(productOptionOne);
        const productOptionTwo = questionFourOptions[1].options.find(
          (x) => x.value === products[i]
        );
        if (productOptionTwo) selectedProducts.push(productOptionTwo);
      }
      setQuestionFourSelectedValues(selectedProducts);
    }
  }, []);

  const { mutate: mutateQualifyUser } = useMutation(putQualifyUser);
  const { mutate: mutateOnboardingFormDisplay } = useMutation(
    putOnboardingFormDisplay
  );
  const { mutate: mutateOnboardingRecommendationDisplay } = useMutation(
    putOnboardingRecommendationDisplay
  );
  const qualifyUser = async () => {
    const platforms = questionTwoSelectedValues.map(
      (platform) => platform.value
    );
    const products = questionFourSelectedValues.map((product) => product.value);
    const data = {
      role:
        questionOneValue === 'Other'
          ? questionOneOtherInputValue
          : questionOneValue,
      industry:
        questionThreeValue === 'Other'
          ? questionThreeOtherInputValue
          : questionThreeValue,
      platforms: platforms.toString(),
      products: products.toString(),
    };
    mutateQualifyUser(data);
    mutateOnboardingFormDisplay();
    userData.userData.intro_form_displayed = true;
    handleCloseShowOnboardingForm();
  };

  const goToDashboard = async () => {
    mutateOnboardingRecommendationDisplay();
    handleCloseShowOnboardingRecommendation();
  };
  return (
    <>
      {showOnboardingForm && (
        <Modal
          show={showOnboardingForm}
          onHide={handleCloseShowOnboardingForm}
          size="xl"
          centered
          contentClassName="onboarding-form-modal"
        >
          <Modal.Header style={{ border: '0 none' }}>
            <Modal.Title>
              <Row className="mt-5 mx-3">
                <Col md={4}>
                  <h1>
                    Welcome to{' '}
                    <span style={{ color: '#3795ff', fontWeight: 'bold' }}>
                      Veeplay
                    </span>
                  </h1>
                </Col>
                <Col md={8}>
                  <p>
                    We’re here to help you{' '}
                    <span style={{ color: '#F44114', fontWeight: 'bold' }}>
                      get the most
                    </span>{' '}
                    of our products.{' '}
                  </p>
                  <p>
                    Please answer a few short questions so we can personalize
                    your Veeplay experience.
                  </p>
                </Col>
              </Row>
            </Modal.Title>
            <Button
              variant="light"
              className="gray-circle-btn"
              onClick={handleCloseShowOnboardingForm}
            >
              <img src="/assets/icons/close-icon.svg" alt="Close Icon" />
            </Button>
          </Modal.Header>
          <Modal.Body className="mx-3">
            <Row>
              <Col md={6}>
                <Form.Label>1. What best describes your role?</Form.Label>
                <MockDropdown
                  usage={'select-options'}
                  verticalHr={true}
                  options={questionOneOptions}
                  dropdownOption={questionOneValue}
                  setDropdownOption={setQuestionOneValue}
                  otherInputValue={questionOneOtherInputValue}
                  setOtherInputValue={setQuestionOneOtherInputValue}
                  otherInputValuePlaceholder={'Enter role'}
                />
              </Col>
              <Col md={6}>
                <Form.Label>
                  2. Pick the platforms for your site / application:{' '}
                </Form.Label>
                <Select
                  options={questionTwoOptions}
                  isMulti={true}
                  styles={colourStyles}
                  closeMenuOnSelect={false}
                  onChange={(e) => handleQuestionTwoChange(e)}
                  formatOptionLabel={checkboxWithIconQuestionOption}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={6}>
                <Form.Label>
                  3. Which industry best describes your site / app?
                </Form.Label>
                <MockDropdown
                  usage={'select-options'}
                  verticalHr={true}
                  options={questionThreeOptions}
                  dropdownOption={questionThreeValue}
                  setDropdownOption={setQuestionThreeValue}
                  otherInputValue={questionThreeOtherInputValue}
                  setOtherInputValue={setQuestionThreeOtherInputValue}
                  otherInputValuePlaceholder={'Enter industry'}
                />
              </Col>
              <Col md={6}>
                <Form.Label>
                  4. Which products / services do you currently need?
                </Form.Label>
                <Select
                  isMulti={true}
                  options={questionFourOptions}
                  closeMenuOnSelect={false}
                  onChange={(e) => handleQuestionFourChange(e)}
                  formatOptionLabel={checkboxQuestionOption}
                />
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer className="mb-3 mx-3 onboarding-form-footer">
            <Button
              variant="primary"
              disabled={canContinueOnboarding}
              onClick={qualifyUser}
            >
              Continue
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {userData.userData.intro_form_displayed && [
        showOnboardingRecommendation && (
          <Modal
            show={showOnboardingRecommendation}
            onHide={handleCloseShowOnboardingRecommendation}
            size="xl"
            centered
            contentClassName="onboarding-form-modal"
          >
            <Modal.Header style={{ border: '0 none' }}>
              <Button
                variant="light"
                className="gray-circle-btn"
                onClick={handleCloseShowOnboardingRecommendation}
              >
                <img src="/assets/icons/close-icon.svg" alt="Close Icon" />
              </Button>
            </Modal.Header>
            <Modal.Body className="mx-3">
              <Row>
                <Col xs={12} md={6}>
                  <h2 style={{ fontWeight: 'bold' }}>
                    Based on previous selection:
                  </h2>
                  <p className="pt-5">
                    Review our integration documentation for your selected
                    platforms:
                  </p>
                  {questionTwoSelectedValues.map((item) => (
                    <ButtonGroup className="w-100 mt-2" key={item.id}>
                      <button
                        variant="outline-secondary"
                        active="true"
                        className={
                          'btn platform-btn ' +
                          (item.value === 'iOS'
                            ? 'btn-application-platform-apple'
                            : item.value === 'Android'
                            ? 'btn-application-platform-android'
                            : item.value === 'Web'
                            ? 'btn-application-platform-javascript'
                            : item.value === 'Smart TV'
                            ? 'btn-application-platform-smart-tv'
                            : '')
                        }
                      >
                        {item.value === 'iOS' ? (
                          <FaApple size={40} color="white" />
                        ) : item.value === 'Android' ? (
                          <FaAndroid
                            className="platform-icon"
                            color="white"
                            size={40}
                          />
                        ) : item.value === 'Web' ? (
                          <DiJavascript1 className="platform-icon" size={40} />
                        ) : item.value === 'Smart TV' ? (
                          <img
                            src="/assets/icons/smart-tv-icon.svg"
                            alt="Smart TV icon"
                            style={{ width: '40px' }}
                          />
                        ) : (
                          ''
                        )}
                      </button>
                      <Button
                        variant="outline-secondary"
                        className="w-100 d-flex align-items-center platform-link"
                        href={item.url}
                        target="_blank"
                        style={{
                          fontWeight: 'bold',
                          color: 'black',
                          textAlign: 'left',
                          paddingLeft: '30px',
                          borderColor: '#D1D1D1',
                        }}
                      >
                        <div className="d-flex justify-content-between w-100">
                          {item.value} SDK Docs
                          <img
                            src="/assets/icons/caret-right-icon.svg"
                            alt="Copy Icon"
                            className="copy-icon copy-icon-api"
                          />
                        </div>
                      </Button>
                    </ButtonGroup>
                  ))}
                  {questionFourSelectedValues.map((item) => (
                    <ButtonGroup className="w-100 mt-2" key={item.value}>
                      <button
                        variant="outline-secondary"
                        active="true"
                        className="btn platform-btn"
                      >
                        <BiCodeCurly size={40} />
                      </button>
                      <Button
                        variant="outline-secondary"
                        className="w-100 d-flex align-items-center platform-link"
                        href={item.url}
                        target="_blank"
                        style={{
                          fontWeight: 'bold',
                          color: 'black',
                          textAlign: 'left',
                          paddingLeft: '30px',
                          borderColor: '#D1D1D1',
                        }}
                      >
                        <div className="d-flex justify-content-between w-100">
                          {item.value} Docs
                          <span>
                            <img src="/assets/icons/caret-right-icon.svg" />
                          </span>
                        </div>
                      </Button>
                    </ButtonGroup>
                  ))}
                </Col>
                <Col xs={6} md={6}>
                  <div style={{ fontWeight: 'bold' }} className="pt-2">
                    NEXT STEPS
                  </div>
                  <p className="pt-5">
                    To start developing using Veeplay add the following to your
                    TRIAL license:
                  </p>
                  <ul>
                    <li>
                      Video assets and webhooks to your streaming app from the{' '}
                      <Button variant="link" className="video-api-link">
                        Video APIs
                      </Button>{' '}
                      page.
                    </li>
                    <li>
                      Web app from the{' '}
                      <span style={{ color: '#0095ff' }}>
                        <a href="/video-player">Video Players</a>
                      </span>{' '}
                      page.
                    </li>
                  </ul>
                  <p>
                    Veeplay can offer a turn key solution containing{' '}
                    <strong>
                      Video Players, Live Streaming, Server Side Ad Insertion,
                      In-player Paywall, DRM,
                    </strong>{' '}
                    through its extended network of partners.
                  </p>
                  <div
                    style={{ color: '#0095FF', fontWeight: 'bold' }}
                    className="pt-4"
                  >
                    Tailor your custom solution today with one of our
                    specialists:
                  </div>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="mb-3 mx-3 onboarding-form-footer">
              <>
                <Button
                  variant="primary"
                  href={'https://calendly.com/veeplay'}
                  target="_blank"
                >
                  SCHEDULE A CALL
                </Button>
                <Button variant="primary" onClick={goToDashboard}>
                  GO TO DASHBOARD
                </Button>
              </>
            </Modal.Footer>
          </Modal>
        ),
      ]}
    </>
  );
};
OnboardingForm.propTypes = {
  userData: PropTypes.any,
};
export default OnboardingForm;
