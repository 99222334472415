import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { remove } from '../utils/Storage';

const Logout = () => {
  async function RemoveToken() {
    remove('userToken');
    remove('userSelectedLicenseID');
    return true;
  }

  return (
    <Fragment>
      {RemoveToken() ? (
        <Redirect to="/logout-panel" />
      ) : (
        <Redirect to="/invalid-token" />
      )}
    </Fragment>
  );
};

export default Logout;
