import { Card, Row, Col, Button, Modal } from 'react-bootstrap';
import React, { useState, useRef, useEffect } from 'react';
import { FaInfoCircle, AiOutlineClose } from 'react-icons/all';
import PropTypes from 'prop-types';
import Toaster from '../components/Toaster';
import { MediaPlayer, MediaUnit } from 'veeplay';
import { getReadableDuration } from '../utils/duration';

const VideoCard = ({ item, handleShowDeleteAsset }) => {
  const [toggleCopyUrl, setToggleCopyUrl] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const targetHLS = useRef(null);
  const targetDASH = useRef(null);
  const targetLowQ = useRef(null);
  const targetMedQ = useRef(null);
  const targetHighQ = useRef(null);
  const [showTooltipHLS, setShowTooltipHLS] = useState(false);
  const [showTooltipDASH, setShowTooltipDASH] = useState(false);
  const [showTooltipLowQ, setShowTooltipLowQ] = useState(false);
  const [showTooltipMedQ, setShowTooltipMedQ] = useState(false);
  const [showTooltipHighQ, setShowTooltipHighQ] = useState(false);

  const {
    status,
    created_at,
    duration,
    metadata: { title },
    output,
  } = item;

  const roundedAssetDuration = Math.floor(duration);
  const readableAssetDuration = getReadableDuration(roundedAssetDuration);

  function convertStringToReadableDate(date) {
    const d = new Date(date);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const dateStr = d.toLocaleDateString('en-US', options);
    return dateStr;
  }

  const handleCopyUrlClick = () => {
    setToggleCopyUrl(!toggleCopyUrl);
  };

  const copyHLSValue = () => {
    navigator.clipboard.writeText(output === undefined ? `` : output.hls);
    setShowTooltipHLS(true);
    setTimeout(() => setShowTooltipHLS(false), 3000);
  };
  const copyDASHValue = () => {
    navigator.clipboard.writeText(output === undefined ? `` : output.dash);
    setShowTooltipDASH(true);
    setTimeout(() => setShowTooltipDASH(false), 3000);
  };
  const copyLowQValue = () => {
    navigator.clipboard.writeText(output === undefined ? `` : output.mp4_low);
    setShowTooltipLowQ(true);
    setTimeout(() => setShowTooltipLowQ(false), 3000);
  };
  const copyMedQValue = () => {
    navigator.clipboard.writeText(
      output === undefined ? `` : output.mp4_medium
    );
    setShowTooltipMedQ(true);
    setTimeout(() => setShowTooltipMedQ(false), 3000);
  };
  const copyHighQValue = () => {
    navigator.clipboard.writeText(output === undefined ? `` : output.mp4_high);
    setShowTooltipHighQ(true);
    setTimeout(() => setShowTooltipHighQ(false), 3000);
  };

  const mp4List = [
    {
      label: 'Low Q',
      value: output === undefined ? `` : output.mp4_low,
      target: targetLowQ,
      copyFunc: copyLowQValue,
      showTooltip: showTooltipLowQ,
    },
    {
      label: 'Med Q',
      value: output === undefined ? `` : output.mp4_medium,
      target: targetMedQ,
      copyFunc: copyMedQValue,
      showTooltip: showTooltipMedQ,
    },
    {
      label: 'High Q',
      value: output === undefined ? `` : output.mp4_high,
      target: targetHighQ,
      copyFunc: copyHighQValue,
      showTooltip: showTooltipHighQ,
    },
  ];

  const cmafList = [
    {
      label: 'HLS',
      value: output === undefined ? `` : output.hls,
      target: targetHLS,
      copyFunc: copyHLSValue,
      showTooltip: showTooltipHLS,
    },
    {
      label: 'DASH',
      value: output === undefined ? `` : output.dash,
      target: targetDASH,
      copyFunc: copyDASHValue,
      showTooltip: showTooltipDASH,
    },
  ];

  const handlePlayClick = () => {
    if (output && output.hls) {
      setShowVideoModal(true);
    }
  };
  const handleVideoModalHide = () => setShowVideoModal(false);

  useEffect(() => {
    if (showVideoModal) {
      const videoUrl = output.hls;
      const unit = new MediaUnit(videoUrl);
      const player = new MediaPlayer('player');

      player.playMediaUnits([unit]);
      player.setMute(true);
    }
  }, [showVideoModal, output]);

  return (
    <>
      {toggleCopyUrl ? (
        <Card className="video-card mt-3 mt-md-0">
          <Card.Header
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              marginTop: '1rem',
            }}
          >
            <div className="d-flex align-items-center justify-content-sm-between w-100">
              <h5 style={{ fontWeight: 'bold', margin: '0' }}>
                Copy Video URLs
              </h5>
              <AiOutlineClose
                onClick={handleCopyUrlClick}
                className="close-icon"
              />
            </div>
          </Card.Header>
          <Card.Body className="pt-0">
            <>
              <Row className="align-items-center mt-md-2">
                <Col
                  xs={9}
                  md={2}
                  style={{ fontWeight: 'bold', color: '#3795fe' }}
                >
                  MP4
                </Col>
                <Col xs={3} md={10}>
                  <FaInfoCircle className="info-icon" />
                </Col>
              </Row>

              {mp4List.map((item, idx) => (
                <Row className="align-items-center mt-md-2" key={idx}>
                  <Col xs={12} md={2} style={{ fontWeight: 'bold' }}>
                    {item.label}
                  </Col>
                  <Col xs={10} md={9}>
                    <div className="px-3 py-1 video-api-key-value text-truncate">
                      {item.value}
                    </div>
                  </Col>
                  <Col xs={2} md={1}>
                    <div className="d-flex justify-content-center justify-content-md-end">
                      <Button
                        className="gray-circle-btn"
                        variant="light"
                        ref={item.target}
                        onClick={item.copyFunc}
                      >
                        <img src="/assets/icons/Copy.svg" alt="Copy Icon" />
                      </Button>
                      <Toaster
                        target={item.target.current}
                        showTooltip={item.showTooltip}
                      />
                    </div>
                  </Col>
                </Row>
              ))}

              <Row className="align-items-center mt-md-4">
                <Col
                  xs={9}
                  md={2}
                  style={{ fontWeight: 'bold', color: '#3795fe' }}
                >
                  CMAF
                </Col>
                <Col xs={3} md={10}>
                  <FaInfoCircle className="info-icon" />
                </Col>
              </Row>

              {cmafList.map((item, idx) => (
                <Row className="align-items-center mt-md-2" key={idx}>
                  <Col xs={12} md={2} style={{ fontWeight: 'bold' }}>
                    {item.label}
                  </Col>
                  <Col xs={10} md={9}>
                    <div className="px-3 py-1 video-api-key-value text-truncate">
                      {item.value}
                    </div>
                  </Col>
                  <Col xs={2} md={1}>
                    <div className="d-flex justify-content-center justify-content-md-end">
                      <Button
                        className="gray-circle-btn"
                        variant="light"
                        ref={item.target}
                        onClick={item.copyFunc}
                      >
                        <img src="/assets/icons/Copy.svg" alt="Copy Icon" />
                      </Button>
                      <Toaster
                        target={item.target.current}
                        showTooltip={item.showTooltip}
                      />
                    </div>
                  </Col>
                </Row>
              ))}
            </>
          </Card.Body>
        </Card>
      ) : (
        <Card className="video-card mt-3 mt-md-0">
          <Card.Body className="p-0">
            <div className="video-img-container">
              <Card.Img
                variant="top"
                src="https://images.g2crowd.com/uploads/product/image/social_landscape/social_landscape_bbaae8f817e46f414974c4ac601ec1ee/veeplay.png"
              />
              {output?.hls && (
                <div
                  style={{
                    position: 'absolute',
                    top: '40%',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  <button className="btn">
                    <img
                      src="/assets/icons/play-icon-hover.svg"
                      alt="play icon hover"
                      onClick={handlePlayClick}
                    />
                  </button>
                </div>
              )}
            </div>

            <Row className="align-items-center px-3 mt-3">
              <Col xs={12} md={6}>
                <Row className="align-items-center">
                  <Col xs={6} md={6}>
                    Status
                  </Col>
                  <Col xs={6} md={6} className="d-flex justify-content-end">
                    <div
                      style={{
                        color:
                          status === 'QUEUED' || status === 'PROGRESSING'
                            ? '#F64113'
                            : status === 'CREATED' || status === 'SUBMITTED'
                            ? '#0095ff'
                            : status === 'COMPLETE'
                            ? 'green'
                            : 'red',
                        fontWeight: '700',
                      }}
                    >
                      {status}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <Row className="align-items-center">
                  <Col xs={6} md={6}>
                    <Button
                      variant="outline-secondary"
                      style={{ borderRadius: '6px' }}
                      size="sm"
                      onClick={handleCopyUrlClick}
                      disabled={!output}
                    >
                      Copy URLS
                    </Button>
                  </Col>
                  <Col xs={6} md={6}>
                    <Button
                      variant="light"
                      className="float-right gray-circle-btn"
                      onClick={() => handleShowDeleteAsset(item)}
                    >
                      <img
                        src="/assets/icons/trash-icon.svg"
                        alt="Trash icon"
                      />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3 px-3 mb-3">
              <Col md={6}>
                <Row>
                  <Col xs={6} md={6}>
                    Created
                  </Col>
                  <Col xs={6} md={6} className="d-flex justify-content-end">
                    {convertStringToReadableDate(created_at)}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col xs={6} md={6}>
                    Duration
                  </Col>
                  <Col xs={6} md={6} className="d-flex justify-content-end">
                    {readableAssetDuration}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
      <Modal
        show={showVideoModal}
        centered
        onHide={handleVideoModalHide}
        contentClassName="p-3 onboarding-form-modal"
        size="lg"
      >
        <Modal.Header style={{ border: 'none' }}>
          <Modal.Title>{title}</Modal.Title>
          <Button
            variant="light"
            className="gray-circle-btn"
            onClick={handleVideoModalHide}
          >
            <img src="/assets/icons/close-icon.svg" alt="Close Icon" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div id="player" className="w-100" style={{ height: 400 }} />
        </Modal.Body>
      </Modal>
    </>
  );
};
VideoCard.propTypes = {
  item: PropTypes.any,
  handleShowDeleteAsset: PropTypes.any,
};
export default VideoCard;
