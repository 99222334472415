import React, { Fragment } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { write } from '../utils/Storage';

const Auth = () => {
  const WriteToken = () => {
    const { token } = useParams();
    try {
      write('userToken', token);
      return true;
    } catch (err) {
      console.log(err.message);
      return false;
    }
  };

  return (
    <Fragment>
      {WriteToken() ? <Redirect to="/" /> : <Redirect to="/invalid-token" />}
    </Fragment>
  );
};

export default Auth;
