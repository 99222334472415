import React, { useState } from 'react';
import { Button, Row, Col, Form, Container, Modal } from 'react-bootstrap';
import { FaAndroid, IoLogoJavascript, GrApple } from 'react-icons/all';
import PropTypes from 'prop-types';

const AddApp = ({
  showAddApp,
  handleCloseshowAddApp,
  submitAddAppForm,
  form,
}) => {
  const [showAppleForm, setshowAppleForm] = useState(false);
  const handleshowAppleForm = () => {
    reset();
    setshowAppleForm(true);
    setshowAndroidForm(false);
    setshowJSForm(false);
  };
  const [showAndroidForm, setshowAndroidForm] = useState(false);
  const handleshowAndroidForm = () => {
    reset();
    setshowAppleForm(false);
    setshowAndroidForm(true);
    setshowJSForm(false);
  };
  const [showJSForm, setshowJSForm] = useState(false);
  const handleshowJSForm = () => {
    reset();
    setshowAppleForm(false);
    setshowAndroidForm(false);
    setshowJSForm(true);
  };

  const { register, handleSubmit, reset } = form;

  return (
    <Modal
      show={showAddApp}
      onHide={handleCloseshowAddApp}
      centered
      contentClassName="onboarding-form-modal p-3"
    >
      <Form onSubmit={handleSubmit(submitAddAppForm)}>
        <Modal.Header className="border-0">
          <Modal.Title>
            <Container>
              <Row>
                <Col>
                  <div className="mt-3" style={{ fontSize: '1.5rem' }}>
                    Register an application
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Title>
          <Button
            variant="light"
            className="gray-circle-btn"
            onClick={handleCloseshowAddApp}
          >
            <img src="/assets/icons/close-icon.svg" alt="Close Icon" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="justify-content-center align-items-center ">
              <Col xs={5}>
                <div>Select platform:</div>
              </Col>
              <Col xs={7}>
                <div className="d-flex align-items-center justify-content-between">
                  <Button
                    variant="outline-secondary"
                    className={
                      showAppleForm
                        ? 'm-2 btn-application-platform-apple'
                        : 'm-2'
                    }
                    style={{ width: '50px', height: '50px' }}
                    onClick={handleshowAppleForm}
                  >
                    <GrApple
                      color={showAppleForm ? '#FFFFFF' : '#ACACAC'}
                      size={26}
                    />
                  </Button>
                  <Button
                    variant="outline-secondary"
                    className={
                      showAndroidForm
                        ? 'm-2 btn-application-platform-android'
                        : 'm-2'
                    }
                    style={{ width: '50px', height: '50px' }}
                    onClick={handleshowAndroidForm}
                  >
                    <FaAndroid
                      color={showAndroidForm ? '#FFFFFF' : '#ACACAC'}
                      size={26}
                    />
                  </Button>
                  <Button
                    variant="outline-secondary"
                    className={
                      showJSForm
                        ? 'm-2 btn-application-platform-javascript'
                        : 'm-2'
                    }
                    style={{ width: '50px', height: '50px' }}
                    onClick={handleshowJSForm}
                  >
                    <IoLogoJavascript
                      color={showJSForm ? '#1D1D1D' : '#ACACAC'}
                      size={26}
                    />
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>

          <Col>
            {showAppleForm ? (
              <>
                <Form.Group>
                  <Form.Label>App name*</Form.Label>
                  <Form.Control
                    type="text"
                    className="w-100"
                    placeholder="App name"
                    {...register('name', { required: true })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>iOS app bundle ID *</Form.Label>
                  <Form.Control
                    type="text"
                    className="w-100"
                    placeholder="Enter your app's bundle identifier"
                    {...register('identifier', { required: true })}
                  />
                  <Form.Control
                    type="hidden"
                    value="ios"
                    {...register('platform')}
                  />
                </Form.Group>
              </>
            ) : null}
            {showAndroidForm ? (
              <>
                <Form.Group>
                  <Form.Label>App name*</Form.Label>
                  <Form.Control
                    type="text"
                    className="w-100"
                    placeholder="App name"
                    {...register('name', { required: true })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Android app package name *</Form.Label>
                  <Form.Control
                    type="text"
                    className="w-100"
                    placeholder="Android app package name"
                    {...register('identifier', { required: true })}
                  />
                  <Form.Control
                    type="hidden"
                    value="android"
                    {...register('platform')}
                  />
                </Form.Group>
              </>
            ) : null}
            {showJSForm ? (
              <>
                <Form.Group>
                  <Form.Label>App name*</Form.Label>
                  <Form.Control
                    type="text"
                    className="w-100"
                    placeholder="App name"
                    {...register('name', { required: true })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Web app domain name *</Form.Label>
                  <Form.Control
                    type="text"
                    className="w-100"
                    placeholder="Enter your app's domain name"
                    {...register('identifier', { required: true })}
                  />
                  <Form.Control
                    type="hidden"
                    value="web"
                    {...register('platform')}
                  />
                </Form.Group>
              </>
            ) : null}
          </Col>
        </Modal.Body>
        <Modal.Footer className="onboarding-form-footer">
          <Button variant="light" onClick={handleCloseshowAddApp}>
            Cancel
          </Button>
          <Button
            variant="primary"
            as="input"
            type="submit"
            value="Done"
          ></Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
AddApp.propTypes = {
  showAddApp: PropTypes.any,
  handleCloseshowAddApp: PropTypes.any,
  submitAddAppForm: PropTypes.any,
  form: PropTypes.any,
};
export default AddApp;
