import React from 'react';
import { Button, Row, Col, Form, Container, Modal } from 'react-bootstrap';
import { FaAndroid, IoLogoJavascript, GrApple } from 'react-icons/all';
import PropTypes from 'prop-types';

const EditApp = ({
  showEditApp,
  handleCloseshowEditApp,
  selectedApp,
  form,
  submitEditAppForm,
}) => {
  const { register, handleSubmit } = form;

  return (
    <Modal
      show={showEditApp}
      onHide={handleCloseshowEditApp}
      backdrop="static"
      centered
      contentClassName="onboarding-form-modal p-3"
    >
      <Form onSubmit={handleSubmit(submitEditAppForm)}>
        <Modal.Header className="border-0">
          <Modal.Title>
            <Container>
              <Row>
                <Col>
                  <div className="mt-3" style={{ fontSize: '1.5rem' }}>
                    Edit registered application
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Title>
          <Button
            variant="light"
            className="gray-circle-btn"
            onClick={handleCloseshowEditApp}
          >
            <img src="/assets/icons/close-icon.svg" alt="Close Icon" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          {(() => {
            if (selectedApp !== undefined) {
              return (
                <Container>
                  <Row>
                    <Col xs={5} sm={2} md={2}>
                      {selectedApp.platform === 'ios' && (
                        <div
                          style={{
                            width: '50px',
                            height: '50px',
                            backgroundColor: '#717171',
                          }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <GrApple size={26} color="#FFFFFF" />
                        </div>
                      )}
                      {selectedApp.platform === 'android' && (
                        <div
                          style={{
                            width: '50px',
                            height: '50px',
                            backgroundColor: '#3DDB85',
                          }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <FaAndroid size={26} color="#FFFFFF" />
                        </div>
                      )}
                      {selectedApp.platform === 'javascript' ||
                        (selectedApp.platform === 'web' && (
                          <div
                            style={{
                              width: '50px',
                              height: '50px',
                              backgroundColor: '#FFE61F',
                            }}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <IoLogoJavascript size={26} color="#1D1D1D" />
                          </div>
                        ))}
                    </Col>
                    <Col xs={7} sm={10} md={10}>
                      <div className="h-100 d-flex align-items-center">
                        <b>
                          {selectedApp.platform === 'ios' && 'iOS App - '}
                          {selectedApp.platform === 'android' &&
                            'Android App - '}
                          {selectedApp.platform === 'javascript' ||
                            (selectedApp.platform === 'web' && 'Web App - ')}
                        </b>{' '}
                        <span>{` ${selectedApp.identifier}`}</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label className="mt-2">
                        <div
                          className={
                            selectedApp.platform !== 'ios' && 'text-capitalize'
                          }
                        >
                          {selectedApp.platform === 'ios'
                            ? 'iOS'
                            : selectedApp.platform}{' '}
                          app bundle ID *
                        </div>
                      </Form.Label>
                      <input
                        type="text"
                        className="w-100 video-api-new-webhook-input"
                        {...register('identifier', {
                          required: true,
                          value: selectedApp.identifier,
                        })}
                      />
                      <input
                        type="hidden"
                        value={selectedApp.platform}
                        {...register('platform')}
                      />
                    </Col>
                  </Row>
                </Container>
              );
            }
          })()}
        </Modal.Body>
        <Modal.Footer className="onboarding-form-footer">
          <Button variant="light" onClick={handleCloseshowEditApp}>
            Cancel
          </Button>
          <Button
            variant="primary"
            as="input"
            type="submit"
            value="Save"
          ></Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
EditApp.propTypes = {
  showEditApp: PropTypes.any,
  handleCloseshowEditApp: PropTypes.any,
  selectedApp: PropTypes.any,
  form: PropTypes.any,
  submitEditAppForm: PropTypes.any,
};
export default EditApp;
