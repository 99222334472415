import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Livestream from '../pages/Livestream';
import AddService from './AddService';
import LivestreamCard from './LivestreamCard';
import PropTypes from 'prop-types';
const CreateLivestream = ({
  put,
  post,
  livestreamName,
  livestreamServices,
  isEdit,
  selectedLicense,
  header,
}) => {
  const [showNoLivestream, setShowNoLivestream] = useState(false);
  const [showAddService, setShowAddService] = useState(false);
  const [enableDoneBtn, setEnableDoneBtn] = useState(true);
  const [enableDoneBtn3rdParty, setEnableDoneBtn3rdParty] = useState(true);
  const [services, setServices] = useState(livestreamServices);
  const [selectedService, setSelectedService] = useState('');
  const [enableOtherInputField, setEnableOtherInputField] = useState(true);
  const [otherService, setOtherService] = useState('');
  const [streamName, setStreamName] = useState(livestreamName);
  const [streamURL, setStreamURL] = useState('');
  const [streamKey, setStreamKey] = useState('');
  const [isEditService, setIsEditService] = useState(null);
  const handleShowNoLivestream = () => setShowNoLivestream(true);

  const handleShowAddService = () => {
    setShowAddService(true);
    setSelectedService('');
    setOtherService('');
    setStreamURL('');
    setStreamKey('');
  };
  const handleShowEditService = (service) => {
    setIsEditService(service.serviceId);
    setSelectedService(service.selectedService);
    setOtherService(service.otherService);
    setStreamURL(service.streamURL);
    setStreamKey(service.streamKey);
    setShowAddService(true);
    if (service.selectedService === 'Other') {
      setEnableOtherInputField(false);
    } else {
      setEnableOtherInputField(true);
    }
  };
  const handleDeleteService = (serviceId) => {
    setServices((services) =>
      services.filter((_, index) => index !== serviceId)
    );
  };
  const handleServiceChange = (e) => {
    if (e.value === 'Other') {
      setEnableOtherInputField(false);
    } else {
      setEnableOtherInputField(true);
      setOtherService('');
    }
    setSelectedService(e.value);
  };
  const handleCloseAddService = () => {
    setIsEditService(null);
    setShowAddService(false);
    setEnableOtherInputField(true);
  };
  const handleSubmitAddService = () => {
    setShowAddService(false);
    setEnableOtherInputField(true);
    setEnableDoneBtn3rdParty(true);
    if (Number.isInteger(isEditService)) {
      services[isEditService] = {
        selectedService: selectedService,
        otherService: otherService,
        streamURL: streamURL,
        streamKey: streamKey,
      };
      setServices(services);
      setIsEditService(null);
    } else {
      setServices([
        ...services,
        {
          selectedService: selectedService,
          otherService: otherService,
          streamURL: streamURL,
          streamKey: streamKey,
        },
      ]);
    }
  };

  useEffect(() => {
    if (streamName.length > 0) {
      setEnableDoneBtn(false);
    } else {
      setEnableDoneBtn(true);
    }
  }, [streamName]);

  const handleSubmitLivestream = () => {
    const data = {
      metadata: { name: streamName },
      max_idle_seconds: 1800,
    };
    if (services.length > 0) {
      data.simulcast = services.map((service) => ({
        url: service.streamURL,
        stream_key: service.streamKey,
      }));

      const metadata_services = services.map((service) => ({
        service_type: service.selectedService,
        service_value: service.otherService,
      }));

      data.metadata = {
        ...data.metadata,
        metadata_services,
      };
    }
    if (isEdit) {
      data.simulcast = services.map((service) => ({
        url: service.streamURL,
        stream_key: service.streamKey,
      }));
      const putData = { body: data, id: isEdit };
      put(putData);
    } else {
      post(data);
    }
    handleShowNoLivestream();
  };

  return (
    <>
      {showNoLivestream ? (
        <Livestream userSelectedLicense={selectedLicense} xAPIkey={header} />
      ) : (
        <Container className="mt-5">
          <AddService
            showAddService={showAddService}
            handleCloseAddService={handleCloseAddService}
            handleSubmitAddService={handleSubmitAddService}
            handleServiceChange={handleServiceChange}
            enableOtherInputField={enableOtherInputField}
            setEnableOtherInputField={setEnableOtherInputField}
            otherService={otherService}
            setOtherService={setOtherService}
            streamURL={streamURL}
            setStreamURL={setStreamURL}
            streamKey={streamKey}
            setStreamKey={setStreamKey}
            selectedService={selectedService}
            isEdit={isEditService}
            enableDoneBtn3rdParty={enableDoneBtn3rdParty}
            setEnableDoneBtn3rdParty={setEnableDoneBtn3rdParty}
          />
          <Row>
            <Col md={6}>
              <h1 className="heading-h1">
                {isEdit ? 'Edit' : 'Create'} Livestream
              </h1>
            </Col>
            <Col md={6}>
              <div className="d-md-flex justify-content-md-end align-items-center">
                <Button
                  variant="outline-secondary"
                  className="btn-cancel"
                  onClick={handleShowNoLivestream}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-done"
                  disabled={enableDoneBtn}
                  onClick={handleSubmitLivestream}
                >
                  Done
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <h4 className="heading-h4">Stream name</h4>
            </Col>
            <Col md={6}>
              <div className="d-flex justify-content-md-end align-items-center">
                <input
                  className="stream-name-input"
                  placeholder="Enter stream name"
                  value={streamName}
                  onChange={(e) => setStreamName(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12}>
              <hr style={{ background: '#D1D1D1' }}></hr>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={10}>
              <div>
                <h4 className="heading-h4">Re-stream (optional)</h4>
                <p className="re-stream-txt">
                  Re-stream your content to any number of 3rd party services
                  like YouTube or Twitch.
                  <br /> A Server URL and a Stream Key must be provided for
                  every added service.
                </p>
              </div>
            </Col>
            <Col md={2}>
              <div className="d-flex justify-content-md-end align-items-center">
                <Button
                  variant="outline-primary"
                  className="btn-add-service"
                  onClick={handleShowAddService}
                >
                  Add Service
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={12}>
              {services.length > 0 ? (
                <>
                  {services.map((item, id) => {
                    return (
                      <LivestreamCard
                        key={id}
                        serviceId={id}
                        selectedService={item.selectedService}
                        otherService={item.otherService}
                        streamURL={item.streamURL}
                        streamKey={item.streamKey}
                        showEditService={handleShowEditService}
                        deleteService={handleDeleteService}
                      />
                    );
                  })}
                </>
              ) : (
                <div className="no-livestream">
                  No 3rd party services added yet.
                </div>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
CreateLivestream.propTypes = {
  put: PropTypes.any,
  post: PropTypes.any,
  livestreamName: PropTypes.any,
  livestreamServices: PropTypes.any,
  isEdit: PropTypes.any,
  selectedLicense: PropTypes.any,
  header: PropTypes.any,
};
export default CreateLivestream;
