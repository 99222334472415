import React from 'react';
import { Button, Container, Row, Modal } from 'react-bootstrap';
import { FaAndroid, IoLogoJavascript, GrApple } from 'react-icons/all';
import PropTypes from 'prop-types';

const DeleteApp = ({
  showDeleteApp,
  handleCloseshowDeleteApp,
  selectedApp,
  deleteApp,
}) => {
  return (
    <Modal
      show={showDeleteApp}
      onHide={handleCloseshowDeleteApp}
      centered
      contentClassName="onboarding-form-modal p-3"
    >
      <Modal.Header className="border-0 text-center w-100">
        <Modal.Title className="w-100 mt-5" style={{ fontSize: '1.5rem' }}>
          Delete app?
        </Modal.Title>
        <Button
          variant="light"
          className="gray-circle-btn"
          onClick={handleCloseshowDeleteApp}
        >
          <img src="/assets/icons/close-icon.svg" alt="Close Icon" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        {selectedApp !== undefined && (
          <div className="justify-content-center">
            <p className="text-center">Careful, this cannot be undone.</p>
            <Container
              className="py-2"
              style={{
                backgroundColor: '#F7F7F7',
                borderRadius: '100px',
              }}
            >
              {selectedApp.platform === 'ios' ? (
                <Row className="align-items-center justify-content-center">
                  <GrApple color="#717171" className="mr-3"></GrApple>
                  <span>
                    <b>iOS App</b> - {selectedApp.identifier}
                  </span>
                </Row>
              ) : selectedApp.platform === 'android' ? (
                <Row className="align-items-center justify-content-center">
                  <FaAndroid color="#717171" className="mr-3"></FaAndroid>
                  <span>
                    <b>Android App</b> - {selectedApp.identifier}
                  </span>
                </Row>
              ) : (
                selectedApp.platform === 'web' && (
                  <Row className="align-items-center justify-content-center">
                    <IoLogoJavascript
                      color="#717171"
                      className="mr-3"
                    ></IoLogoJavascript>
                    <span>
                      <b>Web App</b> - {selectedApp.identifier}
                    </span>
                  </Row>
                )
              )}
            </Container>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-center onboarding-form-footer">
        <Button variant="light" onClick={handleCloseshowDeleteApp}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            deleteApp(selectedApp.id);
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
DeleteApp.propTypes = {
  showDeleteApp: PropTypes.any,
  handleCloseshowDeleteApp: PropTypes.any,
  deleteApp: PropTypes.any,
  selectedApp: PropTypes.any,
};
export default DeleteApp;
