import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { IoMdDownload, SiApachecordova } from 'react-icons/all';
import React from 'react';
import PropTypes from 'prop-types';

const VideoPlayerPlugin = ({ pluginType, title, url, style }) => {
  return (
    <ButtonGroup className="w-100" style={style}>
      <button
        className="btn"
        style={{
          maxWidth: '60px',
          padding: '11px 14px 13px 14px',
          borderRadius: '6px 0px 0px 6px',
          borderColor: '#D1D1D1',
        }}
      >
        {pluginType === 'marlin' ? (
          <img src="/assets/icons/Marlin_Icon.svg" alt="Marlin logo" />
        ) : (
          pluginType === 'cordova' && (
            <SiApachecordova size={30} style={{ color: '#21abd3' }} />
          )
        )}
      </button>
      <a
        className="d-flex align-items-center btn btn-group-item"
        style={{
          fontWeight: 'bold',
          color: 'black',
          textAlign: 'left',
          paddingLeft: '30px',
          borderColor: '#D1D1D1',
        }}
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <div className="d-flex w-100 justify-content-between">
          <div>{title}</div>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div style={{ fontSize: '0.8rem', color: '#717171' }}>GET</div>
            <IoMdDownload
              size={25}
              className="mx-3"
              style={{ color: pluginType === 'marlin' ? '#6368AE' : '21ABD3' }}
            />
          </div>
        </div>
      </a>
    </ButtonGroup>
  );
};

VideoPlayerPlugin.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  pluginType: PropTypes.string,
  style: PropTypes.any,
};
export default VideoPlayerPlugin;
