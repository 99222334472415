import React from 'react';
import { read } from './Storage';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const readToken = () => {
    const token = read('userToken');
    if (token !== null) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        readToken() ? <Component {...props} /> : <Redirect to="/login-panel" />
      }
    />
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.any,
};

export default PrivateRoute;
