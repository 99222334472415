import React, { useState, useRef } from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Toaster from '../components/Toaster';
const LivestreamCard = ({
  serviceId,
  showEditService,
  selectedService,
  otherService,
  streamURL,
  streamKey,
  deleteService,
}) => {
  const targetStreamURL = useRef(null);
  const targetStreamKey = useRef(null);
  const [showTooltipStreamURL, setShowTooltipStreamURL] = useState(false);
  const [showTooltipStreamKey, setShowTooltipStreamKey] = useState(false);
  const copyStreamURLValue = () => {
    navigator.clipboard.writeText(streamURL);
    setShowTooltipStreamURL(true);
    setTimeout(() => setShowTooltipStreamURL(false), 3000);
  };
  const copyStreamKeyValue = () => {
    navigator.clipboard.writeText(streamKey);
    setShowTooltipStreamKey(true);
    setTimeout(() => setShowTooltipStreamKey(false), 3000);
  };
  return (
    <Card className="video-card" style={{ marginBottom: '40px' }}>
      <Card.Header
        style={{
          background: 'transparent',
          border: 'none',
          marginTop: '1rem',
          marginLeft: '1rem',
        }}
      >
        <Row className="mt-2">
          <Col md={10} style={{ display: 'flex' }}>
            {selectedService === 'Youtube' ? (
              <>
                <img
                  src="/assets/icons/youtube-icon.svg"
                  style={{ marginTop: '-1em', marginRight: '24px' }}
                  alt="Youtube icon"
                />
                <p className="stream-name-card">{selectedService}</p>
              </>
            ) : selectedService === 'Twitch' ? (
              <>
                <img
                  src="/assets/icons/twitch-icon.svg"
                  style={{ marginTop: '-1em', marginRight: '24px' }}
                  alt="Twitch icon"
                />
                <p className="stream-name-card">{selectedService}</p>
              </>
            ) : (
              <p className="stream-name-card">{otherService}</p>
            )}
          </Col>
          <Col md={2}>
            <div className="d-md-flex justify-content-md-end align-items-center">
              <Button
                variant="outline-secondary"
                className="btn-edit-service"
                onClick={() => {
                  showEditService({
                    selectedService: selectedService,
                    otherService: otherService,
                    serviceId: serviceId,
                    streamURL: streamURL,
                    streamKey: streamKey,
                  });
                }}
              >
                Edit
              </Button>
              <Button
                variant="light"
                className="gray-circle-btn"
                onClick={() => deleteService(serviceId)}
              >
                <img src="/assets/icons/trash-icon.svg" alt="Trash icon" />
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body
        style={{
          background: 'transparent',
          border: 'none',
          marginLeft: '1rem',
        }}
      >
        <Row>
          <Col
            md={6}
            className="d-flex justify-content align-items-center"
            style={{ marginBottom: '10px' }}
          >
            <p className="livestream-card-label">Stream URL</p>
            <input
              className="livestream-card-input-1"
              value={streamURL}
              disabled
            />
            <Button
              className="gray-circle-btn"
              variant="light"
              ref={targetStreamURL}
              onClick={copyStreamURLValue}
              style={{ marginTop: '-1.2em' }}
            >
              <img src="/assets/icons/Copy.svg" alt="Copy icon" />
            </Button>
            <Toaster
              target={targetStreamURL.current}
              showTooltip={showTooltipStreamURL}
            />
          </Col>
          <Col
            md={6}
            className="d-flex justify-content align-items-center"
            style={{ marginBottom: '10px' }}
          >
            <p className="livestream-card-label">Stream Key</p>
            <input
              className="livestream-card-input-2"
              value={streamKey}
              disabled
            />
            <Button
              className="gray-circle-btn"
              variant="light"
              ref={targetStreamKey}
              onClick={copyStreamKeyValue}
              style={{ marginTop: '-1em' }}
            >
              <img src="/assets/icons/Copy.svg" alt="Copy icon" />
            </Button>
            <Toaster
              target={targetStreamKey.current}
              showTooltip={showTooltipStreamKey}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
LivestreamCard.propTypes = {
  serviceId: PropTypes.any,
  showEditService: PropTypes.any,
  selectedService: PropTypes.any,
  otherService: PropTypes.any,
  streamURL: PropTypes.any,
  streamKey: PropTypes.any,
  deleteService: PropTypes.any,
};
export default LivestreamCard;
