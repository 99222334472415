/* eslint-disable jsx-a11y/anchor-is-valid */

import { Card, Button, Spinner, Row, Col, Modal } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Toaster from './Toaster';
import moment from 'moment';
const LivestreamItem = ({
  item,
  edit,
  livestreamAction,
  isStartStopLoading,
  handleShowDeleteStream,
}) => {
  const {
    stream_id,
    status,
    started_at,
    stopped_at,
    created_at,
    metadata,
    order: { simulcast },
    output,
    input,
    poll,
  } = item;
  const [showModal, setShowModal] = useState(false);
  const [showInputOutputURLsModal, setShowInputOutputURLsModal] =
    useState(false);
  const excludedStatuses = ['ERROR'];
  const handleStartStop = () => setShowModal(true);
  const readable_created_at = moment(created_at).format(
    'MMM Do YYYY / h:mm A / z'
  );

  const handleLivestreamAction = async () => {
    const data = { id: stream_id };
    if (status === 'PENDING') {
      data.action = 'start';
    } else if (status === 'RUNNING') {
      data.action = 'stop';
    }

    await livestreamAction(data);
  };

  const [showTooltipInputServerUrl, setShowTooltipInputServerUrl] =
    useState(false);
  const [showTooltipInputStreamKey, setShowTooltipInputStreamKey] =
    useState(false);
  const targetInputServerUrl = useRef(null);
  const targetInputStreamKey = useRef(null);
  const copyInputServerUrlValue = (value) => {
    navigator.clipboard.writeText(value);
    setShowTooltipInputServerUrl(true);
    setTimeout(() => setShowTooltipInputServerUrl(false), 3000);
  };

  const copyInputStreamKeyValue = (value) => {
    navigator.clipboard.writeText(value);
    setShowTooltipInputStreamKey(true);
    setTimeout(() => setShowTooltipInputStreamKey(false), 3000);
  };

  const [showTooltipOutputLive, setShowTooltipOutputLive] = useState(false);
  const [showTooltipOutputVod, setShowTooltipOutputVod] = useState(false);
  const targetOutputLive = useRef(null);
  const targetOutputVod = useRef(null);
  const copyOutputLiveValue = (value) => {
    navigator.clipboard.writeText(value);
    setShowTooltipOutputLive(true);
    setTimeout(() => setShowTooltipOutputLive(false), 3000);
  };

  const copyOutputVodValue = (value) => {
    navigator.clipboard.writeText(value);
    setShowTooltipOutputVod(true);
    setTimeout(() => setShowTooltipOutputVod(false), 3000);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleYes = () => {
    setShowModal(false);
    handleLivestreamAction();
  };

  const handleSeeInputAndOutputUrlsClick = () => {
    setShowInputOutputURLsModal(true);
  };

  const handleCloseInputOutputUrlsModal = () => {
    setShowInputOutputURLsModal(false);
  };
  const handleThirdPartyServiceLabel = () => {
    let label = 'none';
    if (metadata.metadata_services !== undefined) {
      const services = metadata.metadata_services.map(
        (service) => service.service_type
      );
      if (services.length > 0 && services.length <= 3) {
        label = services.join(', ');
      } else if (services.length >= 4) {
        label = `${services[0]},${services[1]}, +${services.length - 2} more`;
      }
    }
    return label;
  };
  return (
    <React.Fragment>
      <Card className="livestream-item-card mt-3">
        <Card.Header className="livestream-item-header">
          <Row className="align-items-center">
            <Col md={6}>
              <div className="font-weight-bold">{metadata.name}</div>
            </Col>
            <Col md={3}>
              <div className="d-flex">
                <div>Status</div>
                <div
                  className="ml-3 font-weight-bold"
                  style={{
                    color:
                      status === 'PENDING'
                        ? '#FF6F43'
                        : status === 'STARTING'
                        ? '#0095FF'
                        : status === 'RUNNING'
                        ? '#00C95C'
                        : '#F64113',
                  }}
                >
                  {status}
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="d-flex justify-content-end align-items-center">
                {(isStartStopLoading || poll) && (
                  <Spinner
                    animation="border"
                    variant={'secondary'}
                    role="status"
                    size={'sm'}
                  ></Spinner>
                )}
                <button
                  className="ml-2 btn btn-secondary-hover"
                  disabled={status !== 'PENDING' || isStartStopLoading || poll}
                  onClick={() =>
                    edit({
                      name: metadata.name,
                      metadata_services: metadata.metadata_services,
                      services: simulcast,
                      stream_id: stream_id,
                    })
                  }
                >
                  Edit
                </button>
                <button
                  className={
                    'btn ml-3 ' +
                    (status === 'RUNNING'
                      ? 'livestream-item-btn-stop'
                      : status === 'PENDING'
                      ? 'livestream-item-btn-start'
                      : 'btn-disabled')
                  }
                  onClick={handleStartStop}
                  disabled={status === 'STOPPED' || isStartStopLoading || poll}
                >
                  {status === 'RUNNING' ? 'STOP' : 'START'}
                </button>
                <Button
                  variant="light"
                  className="gray-circle-btn"
                  onClick={() => handleShowDeleteStream(item)}
                >
                  <img src="/assets/icons/trash-icon.svg" alt="Trash icon" />
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="livestream-item-body">
          <Row>
            <Col md={6}>
              <div className="d-flex justify-content-between">
                <div className="grey-text">Created</div>
                <div>{readable_created_at} PDT</div>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex justify-content-between">
                <div className="grey-text">3rd party services</div>
                <div>{handleThirdPartyServiceLabel()}</div>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <div className="d-flex justify-content-between">
                <div className="grey-text">Started</div>
                <div>{started_at}</div>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex justify-content-between">
                <div className="grey-text">Stopped</div>
                <div>{stopped_at}</div>
              </div>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="livestream-item-footer">
          <Row className="align-items-center">
            <Col md={9}>
              {status === 'PENDING'
                ? 'Start this stream to allocate a Server URL and a Stream Key to use with your Livestream software.'
                : status === 'STARTING'
                ? 'Stream starting - this might take 1-2 minutes. '
                : status === 'RUNNING'
                ? 'Copy the links below to use with your Livestream software.'
                : 'This livestream has ended.'}
            </Col>
            <Col md={3}>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-secondary-hover"
                  onClick={handleSeeInputAndOutputUrlsClick}
                  disabled={status !== 'RUNNING'}
                >
                  See Input and Output URLs
                </button>
              </div>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <Modal
        centered
        show={showModal}
        contentClassName="onboarding-form-modal"
        onHide={handleCloseModal}
      >
        <Modal.Header style={{ border: 'none', paddingBottom: '0' }}>
          <Modal.Title />
          <Button
            variant="light"
            className="gray-circle-btn"
            onClick={handleCloseModal}
          >
            <img src="/assets/icons/close-icon.svg" alt="Close Icon" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
              {status === 'RUNNING' ? 'Stop stream' : 'Start stream?'}
            </div>
            <div className="mt-3 text-center">
              {status === 'RUNNING'
                ? 'You cannot restart a stopped stream.'
                : "You can only start a livestream once. If you stop it, you won't be able to start again."}
            </div>
            <div className="mt-4">
              <button
                className="btn livestream-btn-cancel"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              {!excludedStatuses.includes(status) && (
                <button
                  className="btn livestream-btn-primary"
                  onClick={handleYes}
                >
                  Yes
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={showInputOutputURLsModal}
        size="lg"
        backdrop="static"
        contentClassName="onboarding-form-modal livestream-input-output-urls-modal"
      >
        <Modal.Header
          style={{ border: 'none', paddingBottom: '0' }}
          onHide={handleCloseInputOutputUrlsModal}
        >
          <div style={{ fontWeight: 'bold' }}>
            {metadata.name} Livestream URLs
          </div>
          <Button
            variant="light"
            className="gray-circle-btn"
            onClick={handleCloseInputOutputUrlsModal}
          >
            <img src="/assets/icons/close-icon.svg" alt="Close Icon" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-3">
            <div className="d-flex justify-content-between mb-3">
              <div style={{ color: '#0095FF', fontWeight: 'bold' }}>
                Input URL and Key
              </div>
              <a
                href="https://docs.veeplay.com/video-api/#tag/Video-Assets"
                target="_blank"
                rel="noreferrer"
              >
                How to connect your livestream software
              </a>
            </div>
            {input !== undefined ? (
              <div>
                <Row className="align-items-center mt-3">
                  <Col xs={12} md={2}>
                    <div className="video-api-label">Stream URL</div>
                  </Col>
                  <Col xs={12} md={10}>
                    <Row className="align-items-center">
                      <Col xs={10} md={11}>
                        <div className="px-3 py-1 video-api-key-value text-nowrap overflow-auto">
                          {input !== undefined ? input.server_url : ''}
                        </div>
                      </Col>
                      <Col xs={2} md={1}>
                        <div className="d-flex justify-content-center">
                          <Button
                            className="gray-circle-btn"
                            variant="light"
                            ref={targetInputServerUrl}
                            onClick={() =>
                              copyInputServerUrlValue(
                                input !== undefined ? input.server_url : ''
                              )
                            }
                          >
                            <img src="/assets/icons/Copy.svg" alt="Copy icon" />
                          </Button>
                          <Toaster
                            target={targetInputServerUrl.current}
                            showTooltip={showTooltipInputServerUrl}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="align-items-center mt-3">
                  <Col xs={12} md={2}>
                    <div className="video-api-label">Stream Key</div>
                  </Col>
                  <Col xs={12} md={10}>
                    <Row className="align-items-center">
                      <Col xs={10} md={11}>
                        <div className="px-3 py-1 video-api-key-value text-nowrap overflow-auto">
                          {input !== undefined ? input.stream_key : ''}
                        </div>
                      </Col>
                      <Col xs={2} md={1}>
                        <div className="d-flex justify-content-center">
                          <Button
                            className="gray-circle-btn"
                            variant="light"
                            ref={targetInputStreamKey}
                            onClick={() =>
                              copyInputStreamKeyValue(
                                input !== undefined ? input.stream_key : ''
                              )
                            }
                          >
                            <img src="/assets/icons/Copy.svg" alt="Copy icon" />
                          </Button>
                          <Toaster
                            target={targetInputStreamKey.current}
                            showTooltip={showTooltipInputStreamKey}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="no-livestream w-100">Not available</div>
            )}

            <div className="d-flex justify-content-between my-3">
              <div style={{ color: '#0095FF', fontWeight: 'bold' }}>
                Output URL and Key
              </div>
              <a
                href="https://docs.veeplay.com/json-configuration/"
                target="_blank"
                rel="noreferrer"
              >
                How to configure your player
              </a>
            </div>
            <Row className="align-items-center mt-3">
              <Col xs={12} md={2}>
                <div className="video-api-label">Live HLS</div>
              </Col>
              <Col xs={12} md={10}>
                <Row className="align-items-center">
                  <Col xs={10} md={11}>
                    <div className="px-3 py-1 video-api-key-value text-nowrap overflow-auto">
                      {output !== undefined ? output.live : ''}
                    </div>
                  </Col>
                  <Col xs={2} md={1}>
                    <div className="d-flex justify-content-center">
                      <Button
                        className="gray-circle-btn"
                        variant="light"
                        ref={targetOutputLive}
                        onClick={() => copyOutputLiveValue(output.live)}
                      >
                        <img src="/assets/icons/Copy.svg" alt="Copy icon" />
                      </Button>
                      <Toaster
                        target={targetOutputLive.current}
                        showTooltip={showTooltipOutputLive}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="align-items-center mt-3">
              <Col xs={12} md={2}>
                <div className="video-api-label">Recording HLS</div>
              </Col>
              <Col xs={12} md={10}>
                <Row className="align-items-center">
                  <Col xs={10} md={11}>
                    <div className="px-3 py-1 video-api-key-value text-nowrap overflow-auto">
                      {output !== undefined ? output.vod : ''}
                    </div>
                  </Col>
                  <Col xs={2} md={1}>
                    <div className="d-flex justify-content-center">
                      <Button
                        className="gray-circle-btn"
                        variant="light"
                        ref={targetOutputVod}
                        onClick={() => copyOutputVodValue(output.vod)}
                      >
                        <img src="/assets/icons/Copy.svg" alt="Copy icon" />
                      </Button>
                      <Toaster
                        target={targetOutputVod.current}
                        showTooltip={showTooltipOutputVod}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
LivestreamItem.propTypes = {
  item: PropTypes.any,
  edit: PropTypes.any,
  livestreamAction: PropTypes.any,
  isStartStopLoading: PropTypes.any,
  handleShowDeleteStream: PropTypes.any,
};
export default LivestreamItem;
