import React, { useState } from 'react';
import { Row, Col, Container, Button, Toast } from 'react-bootstrap';
import ContentStream from './ContentStream';
import ServerSideAdInsertion from '../pages/ServerSideAdInsertion';
import validator from 'validator';
import PropTypes from 'prop-types';
import Restful from '../utils/API';
import { useMutation } from 'react-query';

const VastTag = (props) => {
  const { contentUrl, update, ssaiID } = props;
  const res = new Restful(process.env.REACT_APP_BACKEND_URL);
  const [showInputContentStream, setShowInputContentStream] = useState(false);
  const [showFailedSSAICreation, setShowFailedSSAICreation] = useState(false);
  const [failedPostMessage, setFailedPostMessage] = useState('');
  const [showDemoStream, setShowDemoStream] = useState(false);
  const [showElements, setShowElements] = useState(false);

  const [vastTagUrl, setvastTagUrl] = useState('');
  const [isSaveDataDisabled, setisSaveDataDisabled] = useState(true);
  const handleVastTagUrlChange = (e) => {
    const isValidUrl = validateUrl(e.target.value);
    setisSaveDataDisabled(!isValidUrl);
    setvastTagUrl(e.target.value);
  };

  const validateUrl = (value) => {
    return validator.isURL(value);
  };

  const postSSAIDemoConfig = async (data) => {
    return await res.create(`/api/ssai-configuration`, data);
  };

  const { mutate: mutateAddSSAIDemoConfig } = useMutation(postSSAIDemoConfig, {
    onSuccess: (response) => {
      if (response.success === true) {
        setShowDemoStream(true);
        setShowElements(true);
      } else {
        setFailedPostMessage(response.error);
        setShowFailedSSAICreation(true);
      }
    },
  });

  const putSSAIDemoConfig = async (data) => {
    return await res.update(`/api/ssai-configuration/${data.id}`, data);
  };

  const { mutate: mutateUpdateSSAIDemoConfig } = useMutation(
    putSSAIDemoConfig,
    {
      onSuccess: (response) => {
        if (response.success === true) {
          setShowDemoStream(true);
          setShowElements(true);
        } else {
          setFailedPostMessage(response.error);
          setShowFailedSSAICreation(true);
        }
      },
    }
  );

  const handleShowInputContentStream = () => {
    setShowInputContentStream(true);
  };
  const handleShowDemoStream = () => {
    const data = { content_url: contentUrl, vast_url: vastTagUrl };
    if (update) {
      data.id = ssaiID;
      mutateUpdateSSAIDemoConfig(data);
    } else {
      mutateAddSSAIDemoConfig(data);
    }
  };

  return (
    <div>
      {showDemoStream ? (
        <ServerSideAdInsertion elements={showElements} />
      ) : showInputContentStream ? (
        <ContentStream
          contentUrl={contentUrl}
          update={update}
          ssaiID={ssaiID}
        />
      ) : (
        <Container>
          <React.Fragment>
            <Row className="mt-5">
              <Col md={7}>
                <h1>
                  <span style={{ fontWeight: '700' }}>Step 2</span> - Input a
                  VAST Tag
                </h1>
              </Col>
              <Col md={5} style={{ textAlign: 'right' }}>
                <Button
                  variant="outline-secondary"
                  className="btn-pale-grey"
                  onClick={handleShowInputContentStream}
                >
                  Back
                </Button>
                <Button
                  className="btn-veeplay-blue ml-3"
                  onClick={handleShowDemoStream}
                  disabled={isSaveDataDisabled}
                >
                  {update ? `Update Data` : `Save Data`}
                </Button>
              </Col>
            </Row>
            <hr className="divider"></hr>
            <Row className="mt-5">
              <Col md={6}>
                <p className="list-label">INFO:</p>
                <div>
                  <div className="blue-bullet"></div>
                  <div className="list-item-details">
                    Ideally, the VAST response returns a mezzanine quality MP4
                    rendition that AWS Elemental MediaTailor can transcode.
                  </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <div className="blue-bullet"></div>
                  <div className="list-item-details">
                    If the response from the ADS contains multiple playback
                    renditions, MediaTailor picks the highest quality and
                    resolution MP4 rendition and sends it to the transcoder.
                  </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <div className="blue-bullet"></div>
                  <div className="list-item-details">
                    The ad audio sample rate must be from 16 to 320 kHz.
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <p className="vast-tag-label" style={{ marginBottom: '12px' }}>
                  Enter VAST Tag:
                </p>
                <input
                  placeholder="VAST Tag"
                  onChange={(e) => handleVastTagUrlChange(e)}
                  value={vastTagUrl}
                  className="input-field"
                ></input>
                <Toast show={showFailedSSAICreation} className="mt-3">
                  <Toast.Header closeButton={false}>
                    <strong className="mr-auto">
                      Failed to create SSAI Demo Config
                    </strong>
                  </Toast.Header>
                  <Toast.Body>{failedPostMessage}</Toast.Body>
                </Toast>
              </Col>
            </Row>
          </React.Fragment>
        </Container>
      )}
    </div>
  );
};
VastTag.propTypes = {
  contentUrl: PropTypes.any,
  update: PropTypes.any,
  ssaiID: PropTypes.any,
};
export default VastTag;
