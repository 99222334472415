import React from 'react';
import Overlay from 'react-bootstrap/Overlay';
import PropTypes from 'prop-types';

const Toaster = (props) => {
  return (
    <Overlay target={props.target} show={props.showTooltip} placement="bottom">
      {({ placement, arrowProps, show: _show, popper, ...props }) => (
        <div
          {...props}
          style={{
            backgroundColor: '#f7f7f7',
            padding: '2px 10px',
            color: '#4d4d4d',
            marginTop: '5px',
            zIndex: 9999999,
            borderRadius: '50px',
            ...props.style,
          }}
        >
          Copied to clipboard
        </div>
      )}
    </Overlay>
  );
};
Toaster.propTypes = {
  style: PropTypes.any,
  target: PropTypes.any,
  showTooltip: PropTypes.any,
};
export default Toaster;
