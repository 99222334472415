import React from 'react';
import { Modal, Row, Col, Container, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const LogsModal = ({ showLogsModal, handleCloseLogsModal, logDetails }) => {
  return (
    <Modal show={showLogsModal} centered onHide={handleCloseLogsModal}>
      <Modal.Header className="border-0" style={{ margin: '10px 10px 0 10px' }}>
        <Modal.Title>
          <p className="modal-title">Log Details</p>
        </Modal.Title>
        <Button
          variant="light"
          className="gray-circle-btn"
          onClick={handleCloseLogsModal}
        >
          <img src="/assets/icons/close-icon.svg" alt="Close Icon" />
        </Button>
      </Modal.Header>
      <Modal.Body style={{ height: '350px', overflowY: 'auto' }}>
        <Container>
          <hr />
          <Row className="align-items-center">
            <Col md={7}>
              <div style={{ fontSize: '1rem' }}>
                {logDetails === undefined ? `` : logDetails.eventDescription}
              </div>
            </Col>
            <Col md={1}></Col>
            <Col md={4}>
              {logDetails === undefined ? `` : logDetails.eventTimestamp}
            </Col>
          </Row>
          <hr />
          <div style={{ marginLeft: '15px', marginRight: '15px' }}>
            <pre>
              <code>
                {logDetails === undefined
                  ? ``
                  : JSON.stringify(logDetails, null, 2)}
              </code>
            </pre>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
LogsModal.propTypes = {
  showLogsModal: PropTypes.any,
  handleCloseLogsModal: PropTypes.any,
  logDetails: PropTypes.any,
};
export default LogsModal;
