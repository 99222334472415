import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Auth from './pages/Auth';
import Logout from './pages/Logout';
import Videoapi from './pages/Videoapi';
import Transcoding from './pages/Transcoding';
import Livestream from './pages/Livestream';
import Videoplayer from './pages/Videoplayer';
import ServerSideAdInsertion from './pages/ServerSideAdInsertion';
import AccountSettings from './pages/AccountSettings';
import Usage from './pages/Usage';
import Header from './components/Header';
import Switch from 'react-bootstrap/Switch';
import Footer from './components/Footer';
import PrivateRoute from './utils/PrivateRoute';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
    },
  },
});
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="App">
          <div id="container">
            <Header />
            <div id="main-content">
              <Switch>
                <PrivateRoute exact path="/" component={Videoapi} />
                <PrivateRoute
                  exact
                  path="/transcoding"
                  component={Transcoding}
                />
                <PrivateRoute exact path="/livestream" component={Livestream} />
                <PrivateRoute
                  exact
                  path="/video-player"
                  component={Videoplayer}
                />
                <PrivateRoute
                  exact
                  path="/ssai"
                  component={ServerSideAdInsertion}
                />
                <PrivateRoute exact path="/logout" component={Logout} />
                <PrivateRoute
                  exact
                  path="/account-settings"
                  component={AccountSettings}
                />
                <PrivateRoute exact path="/usage" component={Usage} />
                <Route exact path="/auth/:token" component={Auth} />
                <Route
                  exact
                  path="/login-panel"
                  render={() => {
                    window.location = `${process.env.REACT_APP_BACKEND_URL}/users/sign_in`;
                    return null;
                  }}
                />
                <Route
                  exact
                  path="/logout-panel"
                  render={() => {
                    window.location = `${process.env.REACT_APP_BACKEND_URL}/users/sso_logout`;
                    return null;
                  }}
                />
              </Switch>
            </div>
            <Footer />
          </div>
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
