import _ from 'humanize-duration';

const shortEnglishHumanizer = _.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
    },
  },
});

export const getReadableDuration = (duration) => {
  if (duration === -1) {
    return 'None';
  } else {
    return shortEnglishHumanizer(duration * 1000, {
      spacer: '',
      delimiter: ' ',
    });
  }
};
