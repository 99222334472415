import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/all';
import PropTypes from 'prop-types';
import Select from 'react-select';

const Section = ({
  title,
  description,
  licenses,
  selectedLicense,
  handleLicenseChange,
}) => {
  const renderTooltip = (props) => (
    <Tooltip className="license-tooltip" {...props}>
      <Row>
        <Col style={{ textAlign: 'left' }}>
          <p className="tooltip-license-details">License code</p>
          <p style={{ lineHeight: '0px' }}>{selectedLicense.info.code}</p>
        </Col>
      </Row>
      <Row style={{ paddingTop: '12px' }}>
        <Col md={6} style={{ textAlign: 'left' }}>
          <p className="tooltip-license-details">Status</p>
        </Col>
        <Col md={6} style={{ textAlign: 'right' }}>
          <p style={{ color: '#3DDB85' }}>Active</p>
        </Col>
      </Row>
      <Row>
        <Col md={6} style={{ textAlign: 'left' }}>
          <p className="tooltip-license-details">Expires on</p>
        </Col>
        <Col md={6} style={{ textAlign: 'right' }}>
          <p> {selectedLicense.info.valid_until}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6} style={{ textAlign: 'left' }}>
          <p className="tooltip-license-details">Number of apps</p>
        </Col>
        <Col md={6} style={{ textAlign: 'right' }}>
          <p> {selectedLicense.info.appsLength}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6} style={{ textAlign: 'left' }}>
          <p className="tooltip-license-details">Platforms</p>
        </Col>
        <Col md={6} style={{ textAlign: 'right' }}>
          <p>{selectedLicense.info.platforms.toString()}</p>
        </Col>
      </Row>
    </Tooltip>
  );
  return (
    <React.Fragment>
      <Row>
        <Col xs={12} sm={12} md={6}>
          <h1 className="h1-font-weight">{title}</h1>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <div
            className="d-flex justify-content-md-end align-items-center"
            style={{ marginTop: '7px' }}
          >
            <div>License</div>
            <Col md={4}>
              {' '}
              <Select
                styles={{ indicatorSeparator: () => {} }}
                options={licenses}
                value={selectedLicense}
                isSearchable={false}
                onChange={(e) => handleLicenseChange(e)}
              />
            </Col>

            <OverlayTrigger placement="bottom" overlay={renderTooltip}>
              <AiOutlineInfoCircle />
            </OverlayTrigger>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>{description}</Col>
      </Row>
    </React.Fragment>
  );
};

Section.propTypes = {
  title: PropTypes.any,
  description: PropTypes.any,
  licenses: PropTypes.any,
  selectedLicense: PropTypes.any,
  handleLicenseChange: PropTypes.any,
};
export default Section;
