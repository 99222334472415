/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Button, ButtonGroup } from 'react-bootstrap';
import {
  IoMdDownload,
  FaApple,
  SiApachecordova,
  FaAndroid,
  DiJavascript1,
} from 'react-icons/all';
import AddApp from '../components/AddApp';
import EditApp from '../components/EditApp';
import DeleteApp from '../components/DeleteApp';
import RegisteredApp from '../components/RegisteredApp';
import Restful from '../utils/API';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Section from '../components/Section';
import { write, userSelectedLicenseID } from '../utils/Storage';
import { useForm } from 'react-hook-form';
import { createStreamingApp } from '../utils/stream';
import VideoPlayerPlugin from '../components/VideoPlayerPlugin';

const Videoplayer = (options) => {
  const [res, setRes] = useState(
    new Restful(process.env.REACT_APP_BACKEND_URL)
  );
  const [licenses, setLicenses] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState();
  const queryClient = useQueryClient();
  const fetchLicenses = async () => {
    return res.fetch('/api/licenses');
  };

  const { isLoading, data: licensesData } = useQuery(
    'videoPlayerLicenseList',
    fetchLicenses
  );

  useEffect(() => {
    (async (data) => {
      if (!data) {
        return;
      }
      const statefulLicense = userSelectedLicenseID();
      let license = data.find((license) => license.id === statefulLicense);
      if (!license || license.license_type_id === 4) {
        const excludedTrialLicense = data.filter(function (item) {
          return item.license_type_id !== 4;
        });
        if (excludedTrialLicense.length > 0) {
          license = excludedTrialLicense[0];
        } else {
          license = data[0];
        }
      }
      let streamApp = license['registered_apps'].find(
        (x) => x.platform === 'streaming'
      );
      if (streamApp === undefined) {
        streamApp = await createStreamingApp(res, license.id);
      }

      const header = { 'X-Api-Key': streamApp.api_keys[0].key };
      setRes(new Restful(process.env.REACT_APP_BACKEND_URL, header));

      const mappedLicenses = data.map((license) => ({
        label: license.license_type.name,
        license_type_id: license.license_type_id,
        value: license.id,
        info: {
          code: license.code,
          valid_until: license.valid_until,
          appsLength: license.registered_apps.length,
          platforms: [
            ...new Set(license.registered_apps.map((app) => app.platform)),
          ],
        },
        apps: license['registered_apps'],
      }));
      setLicenses(mappedLicenses);
      let selectedLicense = mappedLicenses.find(
        (license) => license.license_type_id !== 4
      );

      if (selectedLicense === undefined) {
        selectedLicense = mappedLicenses[0];
      }

      write('userSelectedLicenseID', selectedLicense.value);

      setSelectedLicense(selectedLicense);
    })(licensesData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licensesData]);

  const fetchApps = async () => {
    const resData = await res.fetch('/api/licenses');
    const selectedLicenseData = resData.find(
      (app) => app.id === selectedLicense.value
    );
    if (selectedLicenseData !== undefined) {
      setRegisteredApps(selectedLicenseData['registered_apps']);
    }
  };

  const {
    isSuccess: getAssetList,
    isLoading: isLoadingAssets,
    refetch: refetchAppsList,
  } = useQuery('appsList', fetchApps, {
    enabled: selectedLicense != null,
  });

  const [showAppleGuide, setshowAppleGuide] = useState(true);
  const handleshowAppleGuide = () => {
    setshowAppleGuide(true);
    setshowAndroidGuide(false);
    setshowJSGuide(false);
  };
  const description =
    "Veeplay's multiplatform video player framework delivers secure,\n" +
    '            reliable streaming & monetization options for publishers of all\n' +
    '            sizes. Develop solid video experiences in just a few lines of code.';
  const [showAndroidGuide, setshowAndroidGuide] = useState(false);
  const handleshowAndroidGuide = () => {
    setshowAppleGuide(false);
    setshowAndroidGuide(true);
    setshowJSGuide(false);
  };
  const [showJSGuide, setshowJSGuide] = useState(false);
  const handleshowJSGuide = () => {
    setshowAppleGuide(false);
    setshowAndroidGuide(false);
    setshowJSGuide(true);
  };

  const [showAddApp, setshowAddApp] = useState(false);
  const handleShowshowAddApp = () => setshowAddApp(true);
  const handleCloseshowAddApp = () => setshowAddApp(false);

  const [selectedApp, setSelectedApp] = useState();
  const [showEditApp, setShowEditApp] = useState(false);
  function handleShowshowEditApp(app) {
    setSelectedApp(app);

    setShowEditApp(true);
  }
  const handleCloseshowEditApp = () => setShowEditApp(false);

  const [showDeleteApp, setshowDeleteApp] = useState(false);
  function handleShowshowDeleteApp(app) {
    setSelectedApp(app);
    setshowDeleteApp(true);
  }

  const handleCloseshowDeleteApp = () => setshowDeleteApp(false);

  const [registeredApps, setRegisteredApps] = useState([]);
  const handleLicenseChange = (e) => {
    const selLicense = licenses.find((license) => license.value === e.value);
    const selLicenseStreamApp = selLicense.apps.find(
      (app) => app.platform === 'streaming'
    );
    if (selLicenseStreamApp !== undefined) {
      const header = { 'X-Api-Key': selLicenseStreamApp.api_keys[0].key };
      setRes(new Restful(process.env.REACT_APP_BACKEND_URL, header));
    }
    setSelectedLicense(selLicense);
  };

  useEffect(() => {
    if (selectedLicense) {
      refetchAppsList();
    }
  }, [res, refetchAppsList, selectedLicense]);

  const addForm = useForm();
  const editForm = useForm();

  const postApp = async (data) => {
    return res.create(`/api/licenses/${selectedLicense.value}/apps`, data);
  };

  const { mutate: mutateAddApp, isLoading: isAddingApp } = useMutation(
    postApp,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('appsList');
      },
    }
  );

  const handleSubmitAddAppForm = (data) => {
    mutateAddApp(data);
    addForm.reset();
    setshowAddApp(false);
  };

  const deleteApp = async (id) => {
    return res.delete(`/api/licenses/${selectedLicense.value}/apps`, id);
  };

  const { mutate: mutateDeleteApp } = useMutation(deleteApp, {
    onSuccess: () => {
      queryClient.invalidateQueries('appsList');
    },
  });

  const handleDeleteApp = (id) => {
    mutateDeleteApp(id);
    setshowDeleteApp(false);
  };

  const putApp = async (data) => {
    return res.update(
      `/api/licenses/${selectedLicense.value}/apps/${data.appId}`,
      data.params
    );
  };

  const { mutate: mutateUpdateApp } = useMutation(putApp, {
    onSuccess: () => {
      queryClient.invalidateQueries('appsList');
    },
  });

  const handleSubmitEditAppForm = (data) => {
    const editedApp = {
      appId: selectedApp.id,
      params: { identifier: data.identifier },
    };
    mutateUpdateApp(editedApp);
    editForm.reset();
    setShowEditApp(false);
  };

  if (isLoading) return <Container></Container>;
  return (
    <Container className="mt-5">
      <EditApp
        showEditApp={showEditApp}
        selectedApp={selectedApp}
        handleCloseshowEditApp={handleCloseshowEditApp}
        submitEditAppForm={handleSubmitEditAppForm}
        form={editForm}
      ></EditApp>
      <DeleteApp
        showDeleteApp={showDeleteApp}
        selectedApp={selectedApp}
        deleteApp={handleDeleteApp}
        handleCloseshowDeleteApp={handleCloseshowDeleteApp}
      ></DeleteApp>
      <AddApp
        showAddApp={showAddApp}
        handleCloseshowAddApp={handleCloseshowAddApp}
        submitAddAppForm={handleSubmitAddAppForm}
        form={addForm}
      ></AddApp>
      <React.Fragment>
        <Section
          title="Video Player"
          description={description}
          licenses={licenses}
          selectedLicense={selectedLicense}
          handleLicenseChange={handleLicenseChange}
        />
        <Row className="mt-5">
          <Col xs={6}>
            <h3>
              Registered apps (
              {
                registeredApps.filter((app) => app.platform !== 'streaming')
                  .length
              }
              )
            </h3>
          </Col>
          <Col xs={6}>
            <div className="d-flex justify-content-md-end">
              <Button
                variant="primary"
                onClick={handleShowshowAddApp}
                disabled={isAddingApp}
              >
                Add App
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={12}>
            {registeredApps.length > 0 ? (
              <>
                {registeredApps.map((app, idx) => {
                  if (app.platform !== 'streaming') {
                    return (
                      <RegisteredApp
                        key={idx}
                        app={app}
                        handleShowshowEditApp={handleShowshowEditApp}
                        handleShowshowDeleteApp={handleShowshowDeleteApp}
                      ></RegisteredApp>
                    );
                  }

                  return <div key={idx}></div>;
                })}
              </>
            ) : (
              <div
                style={{
                  background: '#F7F7F7',
                  textAlign: 'center',
                  borderRadius: '5px',
                  padding: '30px 0px 30px 0px',
                }}
              >
                To start using the player, register an application.
              </div>
            )}
          </Col>
        </Row>

        <Row className="mt-5">
          <Col sm={12} md={12} style={{ marginTop: '40px' }}>
            <div style={{ float: 'left', marginTop: '10px' }}>
              <h3 style={{ fontWeight: 'bold' }}>Installation Guide for:</h3>
            </div>
            <div style={{ float: 'left', marginLeft: '4%' }}>
              <button
                variant="outline-secondary"
                className={
                  'btn platform-btn ' +
                  (showAppleGuide && 'btn-application-platform-apple')
                }
                style={{
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  borderRadius: '10px',
                }}
                onClick={handleshowAppleGuide}
              >
                <FaApple
                  className="platform-icon"
                  color={showAppleGuide ? '#FFFFFF' : '#ACACAC'}
                  size={40}
                />
              </button>
              <button
                variant="outline-secondary"
                className={
                  'btn platform-btn ' +
                  (showAndroidGuide && 'btn-application-platform-android')
                }
                style={{
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  borderRadius: '10px',
                  marginLeft: '25px',
                }}
                onClick={handleshowAndroidGuide}
              >
                <FaAndroid
                  className="platform-icon"
                  color={showAndroidGuide ? '#FFFFFF' : '#ACACAC'}
                  size={40}
                />
              </button>
              <button
                className={
                  'btn platform-btn ' +
                  (showJSGuide && 'btn-application-platform-javascript')
                }
                style={{
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  borderRadius: '10px',
                  marginLeft: '25px',
                }}
                onClick={handleshowJSGuide}
              >
                <DiJavascript1
                  className="platform-icon"
                  color={showJSGuide ? '#1D1D1D' : '#ACACAC'}
                  size={40}
                />
              </button>
            </div>
          </Col>
        </Row>
        {showAppleGuide ? (
          <Row className="mt-5">
            <Col xs={12}>
              <div>
                <h3>iOS SDK & Documentation</h3>
              </div>
              <Row className="mt-5">
                <Col md={6}>
                  <img
                    src="/assets/iOSGuideGraphic.svg"
                    alt="iOS Guide Graphic"
                    className="w-100"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <div className="mt-md-0 mt-3">
                    <h5>
                      <b>DOCS</b>
                    </h5>
                    <p>
                      Read our <b>full documentation</b> on setting up and using
                      the iOS Video Player SDK in our developer portal.
                    </p>
                    <a
                      href="https://docs.veeplay.com/ios-player/readme.html"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-secondary-outline btn-secondary-hover"
                    >
                      iOS Player Docs
                    </a>
                  </div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs={12} md={6}>
                  <div>
                    <h6>
                      <b>GET SDK</b>
                    </h6>
                  </div>
                  <ButtonGroup className="w-100">
                    <button
                      style={{
                        padding: '11px 14px 13px 14px',
                        borderRadius: '6px 0px 0px 6px',
                        borderColor: '#D1D1D1',
                        background: '#717171',
                      }}
                    >
                      <FaApple size={30} color="white" />
                    </button>
                    <a
                      className="d-flex align-items-center btn btn-group-item"
                      style={{
                        fontWeight: 'bold',
                        color: 'black',
                        textAlign: 'left',
                        paddingLeft: '30px',
                        borderColor: '#D1D1D1',
                      }}
                      href="https://github.com/veeplay/ios-media-player"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="d-flex w-100 justify-content-between">
                        <div>Veeplay iOS SDK - 2.2</div>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <div style={{ fontSize: '0.8rem', color: '#717171' }}>
                            GET
                          </div>
                          <IoMdDownload
                            size={25}
                            className="mx-3"
                            style={{ color: '#717171' }}
                          />
                        </div>
                      </div>
                    </a>
                  </ButtonGroup>
                  <div className="mt-3">
                    <div className="font-weight-bold">Using CocoaPods</div>
                    <div
                      className="p-3 w-100"
                      style={{ background: '#f7f7f7' }}
                    >
                      <pre style={{ margin: '0px' }}>
                        <code>pod &quot;Veeplay&quot;</code>
                      </pre>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="mt-3 mt-md-0">
                    <h6>
                      <b>PLUGINS</b>
                    </h6>
                  </div>
                  <VideoPlayerPlugin
                    pluginType="marlin"
                    title="Marlin DRM for iOS - 2.2"
                    url="https://github.com/veeplay/ios-marlin-drm"
                  ></VideoPlayerPlugin>
                  <VideoPlayerPlugin
                    pluginType="cordova"
                    title="Cordova Plugin - 2.2"
                    url="https://github.com/veeplay/veeplay-cordova-plugin"
                    style={{ marginTop: '2.5rem' }}
                  ></VideoPlayerPlugin>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
        {showAndroidGuide ? (
          <Row className="mt-5">
            <Col>
              <div>
                <h3>Android SDK & Documentation</h3>
              </div>
              <Row className="mt-5">
                <Col md={6}>
                  <img
                    src="/assets/AndroidGuideGraphic.svg"
                    alt="Android Guide Graphic"
                    className="w-100"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <div className="mt-md-0 mt-3">
                    <h5>
                      <b>DOCS</b>
                    </h5>
                    <p>
                      Read our <b>full documentation</b> on setting up and using
                      the Android Video Player SDK in our developer portal.
                    </p>
                    <a
                      className="btn btn-secondary-outline btn-secondary-hover"
                      href="https://docs.veeplay.com/android-player/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Android Player Docs
                    </a>
                  </div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md={6}>
                  <div>
                    <h6>
                      <b>GET SDK</b>
                    </h6>
                  </div>
                  <div
                    className="d-flex flex-row align-items-center w-100"
                    style={{
                      border: '1px solid #D1D1D1',
                      borderRadius: '6px',
                    }}
                  >
                    <div
                      style={{
                        padding: '11px 14px 13px 14px',
                        background: '#3ddb85',
                      }}
                    >
                      <FaAndroid size={30} color="white" />
                    </div>
                    <div
                      style={{
                        fontWeight: 'bold',
                        color: 'black',
                        width: '440px',
                        textAlign: 'left',
                        paddingLeft: '30px',
                        borderColor: '#D1D1D1',
                      }}
                    >
                      Veeplay Android SDK - 2.2
                    </div>
                  </div>
                  <div style={{ marginTop: '40px' }}>
                    <h6>GRADLE DEPENDENCY</h6>
                  </div>
                  <div
                    className="mt-4 p-3 w-100"
                    style={{ background: '#f7f7f7' }}
                  >
                    <pre style={{ margin: '0px' }}>
                      <code>
                        {`repositories {
  maven {
    url "https://s3-eu-west-1.amazonaws.com/android.veeplay.com/releases"
  }
}
dependencies {
  compile 'com.veeplay:veeplay-player-android:3.1.25'
}
                        `}
                      </code>
                    </pre>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mt-3 mt-md-0">
                    <h6>
                      <b>PLUGINS</b>
                    </h6>
                  </div>
                  <VideoPlayerPlugin
                    pluginType="marlin"
                    title="Marlin DRM for Android - 2.2"
                    url="https://github.com/veeplay/marlin-android"
                  ></VideoPlayerPlugin>
                  <VideoPlayerPlugin
                    pluginType="cordova"
                    title="Cordova Plugin - 2.2"
                    url="https://github.com/veeplay/veeplay-cordova-plugin"
                    style={{ marginTop: '2.5rem' }}
                  ></VideoPlayerPlugin>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
        {showJSGuide ? (
          <Row className="mt-5">
            <Col>
              <div>
                <h3>Web SDK & Documentation</h3>
              </div>
              <Row className="mt-5">
                <Col md={6}>
                  <img
                    src="/assets/WebGuideGraphic.svg"
                    alt="Web Guide Graphic"
                    className="w-100"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <div className="mt-md-0 mt-3">
                    <h5>
                      <b>DOCS</b>
                    </h5>
                    <p>
                      Read our <b>full documentation</b> on setting up and using
                      the JavaScript Video Player SDK in our developer portal.
                    </p>
                    <a
                      className="btn btn-secondary-outline btn-secondary-hover"
                      href="https://docs.veeplay.com/javascript-player/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Web Player Docs
                    </a>
                  </div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md={6}>
                  <div>
                    <h6>
                      <b>GET SDK</b>
                    </h6>
                  </div>
                  <div
                    className="d-flex flex-row align-items-center w-100"
                    style={{
                      border: '1px solid #D1D1D1',
                      borderRadius: '6px',
                    }}
                  >
                    <div
                      style={{
                        padding: '11px 14px 13px 14px',
                        background: '#FFE61F',
                      }}
                    >
                      <DiJavascript1 size={30} style={{ color: 'black' }} />
                    </div>
                    <div
                      style={{
                        fontWeight: 'bold',
                        color: 'black',
                        width: '440px',
                        textAlign: 'left',
                        paddingLeft: '30px',
                        borderColor: '#D1D1D1',
                      }}
                    >
                      Veeplay Web SDK - 2.2
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="font-weight-bold">From CDN</div>
                    <div
                      className="p-3 w-100"
                      style={{ background: '#f7f7f7' }}
                    >
                      <pre style={{ margin: '0px' }}>
                        <code>
                          &lt;script
                          src=`&quot;`https://cdn.veeplay.com/veeplay-web/latest/mp.bundle.js`&quot;`&gt;&lt;/script&gt;
                        </code>
                      </pre>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="font-weight-bold">From NPM</div>
                    <div
                      className="p-3 w-100"
                      style={{ background: '#f7f7f7' }}
                    >
                      <pre style={{ margin: '0px' }}>
                        <code>npm install veeplay</code>
                      </pre>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mt-3 mt-md-0">
                    <h6>
                      <b>PLUGINS</b>
                    </h6>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
        {/* <Row className="mt-5">
          <Col>
            <h3 style={{ marginBottom: 50 }}>Getting started</h3>
            <ul style={{ paddingLeft: '35px' }} className="mt-3">
              <li style={{ paddingLeft: '25px' }}>
                <h2 style={{ fontWeight: 'bold' }}>Section Header (H2)</h2>
                <h4 className="mt-3">Sub-header (H4)</h4>
                <div className="mt-3 p-3" style={{ background: '#f7f7f7' }}>
                  <pre style={{ margin: '0px' }}>
                    <code>
                      &lt;script
                      src=`&quot;`https://cdn.veeplay.com/veeplay-web/latest/mp.bundle.js`&quot;`&gt;&lt;/script&gt;
                    </code>
                  </pre>
                </div>
                <p className="mt-3">Line of text: </p>
                <div className="p-3" style={{ background: '#f7f7f7' }}>
                  <pre style={{ margin: '0px' }}>
                    <code>
                      &lt;script
                      src=`&quot;`https://cdn.veeplay.com/veeplay-web/VERSION/mp.bundle.js`&quot;`&gt;&lt;/script&gt;
                    </code>
                  </pre>
                </div>
                <h4 className="mt-3">Section Header (H4)</h4>
                <div className="mt-3 p-3" style={{ background: '#f7f7f7' }}>
                  <pre style={{ margin: '0px' }}>
                    <code>npm i veeplay</code>
                  </pre>
                </div>
                <div className="mt-3 p-3" style={{ background: '#f7f7f7' }}>
                  <pre style={{ margin: '0px' }}>
                    <code>
                      {`const { MediaPlayer, NOTIFICATIONS } = require('veeplay')`}
                    </code>
                  </pre>
                </div>
              </li>
              <li style={{ paddingLeft: '25px' }}>
                <h2 className="mt-5" style={{ fontWeight: 'bold' }}>
                  Section Header (H2)
                </h2>
                <h3 className="mt-5">Sub-header (H3)</h3>
                <p className="mt-5">
                  Paragraph, far far away, behind the inline link, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                  They live in Bookmarksgrove right at the coast of the
                  Semantics.{' '}
                </p>
                <div className="mt-4 p-3" style={{ background: '#f7f7f7' }}>
                  <pre style={{ margin: '0px' }}>
                    <code>
                      {`<div id="player"></div>
<script src="<CDN_URL>"></script>
<script>
(async () => {
    try {
        // instantiate main player object
        const player = new MediaPlayer('player');
        // instantiate a MediaBuilder object used for generating MediaUnits for configuring the main content and any abreaks
        const builder = new MediaBuilder(player);
    })();
</script>
                      `}
                    </code>
                  </pre>
                </div>
              </li>
            </ul>
          </Col>
        </Row> */}
      </React.Fragment>
    </Container>
  );
};

export default Videoplayer;
