import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import CreateLivestream from '../components/CreateLivestream';
import LivestreamItem from '../components/LivestreamItem';
import Restful from '../utils/API';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Select from 'react-select';
import { AiOutlineInfoCircle } from 'react-icons/all';
import { userSelectedLicenseID, write } from '../utils/Storage';
import { createStreamingApp } from '../utils/stream';
import useInterval from '../utils/polling';
import _ from 'lodash';
import PropTypes from 'prop-types';
import DeleteStream from '../components/DeleteStream';

const Livestream = ({ userSelectedLicense, xAPIkey }) => {
  const [res, setRes] = useState(
    new Restful(process.env.REACT_APP_BACKEND_URL)
  );
  const queryClient = useQueryClient();

  const renderTooltip = (props) => (
    <Tooltip className="license-tooltip" {...props}>
      <Row>
        <Col style={{ textAlign: 'left' }}>
          <p className="tooltip-license-details">License code</p>
          <p style={{ lineHeight: '0px' }}>{selectedLicense.info.code}</p>
        </Col>
      </Row>
      <Row style={{ paddingTop: '12px' }}>
        <Col md={6} style={{ textAlign: 'left' }}>
          <p className="tooltip-license-details">Status</p>
        </Col>
        <Col md={6} style={{ textAlign: 'right' }}>
          <p style={{ color: '#3DDB85' }}>Active</p>
        </Col>
      </Row>
      <Row>
        <Col md={6} style={{ textAlign: 'left' }}>
          <p className="tooltip-license-details">Expires on</p>
        </Col>
        <Col md={6} style={{ textAlign: 'right' }}>
          <p> {selectedLicense.info.valid_until}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6} style={{ textAlign: 'left' }}>
          <p className="tooltip-license-details">Number of apps</p>
        </Col>
        <Col md={6} style={{ textAlign: 'right' }}>
          <p> {selectedLicense.info.appsLength}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6} style={{ textAlign: 'left' }}>
          <p className="tooltip-license-details">Platforms</p>
        </Col>
        <Col md={6} style={{ textAlign: 'right' }}>
          <p>{selectedLicense.info.platforms.toString()}</p>
        </Col>
      </Row>
    </Tooltip>
  );
  const [livestreamList, setLivestreamList] = useState([]);
  const [livestreamName, setLivestreamName] = useState('');
  const [livestreamServices, setLivestreamServices] = useState([]);
  const [editLivestreamId, setEditLivestreamId] = useState();
  const [selectedLicense, setSelectedLicense] = useState();
  const [licenses, setLicenses] = useState([]);
  const [showDeleteLivestreamModal, setShowDeleteLivestreamModal] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [livestreamID, setLivestreamID] = useState('');
  const [selectedStream, setSelectedStream] = useState();
  const fetchLicensesAndApps = async () => {
    return res.fetch('/api/licenses');
  };

  const { data: license } = useQuery('licenses', fetchLicensesAndApps);

  useEffect(() => {
    (async (data) => {
      if (!data) {
        return;
      }
      const statefulLicense = userSelectedLicenseID();
      let license = data.find((license) => license.id === statefulLicense);
      if (!license || license.license_type_id === 4) {
        const excludedTrialLicense = data.filter(function (item) {
          return item.license_type_id !== 4;
        });
        if (excludedTrialLicense.length > 0) {
          license = excludedTrialLicense[0];
        } else {
          license = data[0];
        }
      }
      const licenseId = license.id;
      let streamApp = license['registered_apps'].find(
        (x) => x.platform === 'streaming'
      );
      if (streamApp === undefined) {
        streamApp = await createStreamingApp(res, licenseId);
      }

      if (xAPIkey !== undefined) {
        const header = { 'X-Api-Key': xAPIkey };
        setRes(new Restful(process.env.REACT_APP_BACKEND_URL, header));
      } else {
        const header = { 'X-Api-Key': streamApp.api_keys[0].key };
        setRes(new Restful(process.env.REACT_APP_BACKEND_URL, header));
      }

      const mappedLicenses = data.map((license) => ({
        label: license.license_type.name,
        license_type_id: license.license_type_id,
        value: license.id,
        info: {
          code: license.code,
          valid_until: license.valid_until,
          appsLength: license.registered_apps.length,
          platforms: [
            ...new Set(license.registered_apps.map((app) => app.platform)),
          ],
        },
        apps: license['registered_apps'],
      }));
      setLicenses(mappedLicenses);
      let selectedLicense = mappedLicenses.find(
        (license) => license.license_type_id !== 4
      );

      if (selectedLicense === undefined) {
        selectedLicense = mappedLicenses[0];
      }

      write('userSelectedLicenseID', selectedLicense.value);

      if (userSelectedLicense !== undefined) {
        setSelectedLicense(userSelectedLicense);
      } else {
        setSelectedLicense(selectedLicense);
      }
    })(license);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [license]);

  const fetchLivestreams = async () => {
    const data = await res.fetch('/stream/live');
    const dataWithPoll = data.map((v) => ({
      ...v,
      poll: ['STARTING', 'PROVISIONING', 'PROVISIONED', 'STOPPING'].includes(
        v.status
      ),
    }));
    setLivestreamList(dataWithPoll);
  };

  const { isSuccess: getLivetreamListSuccess, refetch } = useQuery(
    'liveStreams',
    fetchLivestreams,
    {
      enabled: selectedLicense != null,
    }
  );

  const postLivestream = async (data) => {
    return await res.create(`/stream/live/`, {}, data);
  };

  const { mutate: mutatePostLivestream } = useMutation(postLivestream, {
    onSuccess: () => {
      queryClient.invalidateQueries('liveStreams');
    },
  });

  const putLivestream = async (data) => {
    return await res.update(`/stream/live/${data.id}`, {}, data.body);
  };

  const { mutate: mutatePutLivestream } = useMutation(putLivestream, {
    onSuccess: () => {
      queryClient.invalidateQueries('liveStreams');
    },
  });

  const livestreamAction = async (data) => {
    setLivestreamID(data.id);
    await res
      .update(`/stream/live/${data.id}/${data.action}`)
      .then((response) => {
        // no need if-else. Regardless if data.action is 'start' or 'stop',
        // poll will be set to true to initiate polling
        const updatedLivestreamList = livestreamList.map((livestreamItem) =>
          livestreamItem.stream_id === data.id
            ? { ...livestreamItem, poll: true }
            : livestreamItem
        );

        // updating livestream list with new values will trigger useInterval
        // for each livestream item in LivestreamItem.js which will handle polling
        setLivestreamList(updatedLivestreamList);
      })
      .catch(() => {
        console.log('error on start/stop');
        // set poll to false to stop polling for the livestream item if error
        const updatedLivestreamList = livestreamList.map((livestreamItem) =>
          livestreamItem.stream_id === data.id
            ? { ...livestreamItem, poll: false }
            : livestreamItem
        );
        setLivestreamList(updatedLivestreamList);
      });
  };

  function getLivestream(id) {
    const url = `/stream/live/${id}`;
    return new Promise((resolve, reject) => {
      res.fetch(url).then((data) => {
        resolve(data);
      });
    });
  }

  useInterval(() => {
    if (livestreamList.find((livestream) => livestream.poll === true)) {
      const toPollList = livestreamList.filter(
        (livestream) => livestream.poll === true
      );
      const livestreamRequests = [];
      toPollList.forEach((livestream) =>
        livestreamRequests.push(getLivestream(livestream.stream_id))
      );
      Promise.all(livestreamRequests).then((allLivestreamData) => {
        if (!_.isEqual(allLivestreamData, livestreamList)) {
          // find and replace updated livestream, and update list
          const updatedLivestreamList = [...livestreamList];
          allLivestreamData.forEach((livestream) => {
            const outdatedLivestreamIndex = updatedLivestreamList.findIndex(
              (outDatedLivestream) =>
                outDatedLivestream.stream_id === livestream.stream_id
            );
            if (
              !_.isEqual(livestream, livestreamList[outdatedLivestreamIndex])
            ) {
              updatedLivestreamList[outdatedLivestreamIndex] = livestream;
            }
          });

          if (!_.isEqual(updatedLivestreamList, livestreamList)) {
            console.log('updated and livestream are not equal. Setting new..');
            setLivestreamList(updatedLivestreamList);
          }
        }
      });
    }
  }, 5000);

  const { mutate: mutateLivestreamAction, isLoading: isStartStopLoading } =
    useMutation(livestreamAction);

  const deleteLivestream = async (id) => {
    setIsDeleting(true);
    return await res.delete(`/stream/live/`, id);
  };

  const { mutate: mutateDeleteLivestream } = useMutation(deleteLivestream, {
    onSuccess: () => {
      setIsDeleting(false);
      setShowDeleteLivestreamModal(false);
      queryClient.invalidateQueries('liveStreams');
    },
  });

  const [showCreateLivestream, setShowCreateLivestream] = useState(false);
  const handleShowCreateLivestream = () => {
    setEditLivestreamId();
    setShowCreateLivestream(true);
  };

  const handleShowEditLivestream = (livestream) => {
    setLivestreamName(livestream.name);
    if (livestream.services !== null) {
      setLivestreamServices(
        livestream.services.map(({ url, stream_key }, idx) => ({
          selectedService: livestream.metadata_services[idx].service_type,
          otherService: livestream.metadata_services[idx].service_value,
          streamURL: url,
          streamKey: stream_key,
        }))
      );
    }
    setEditLivestreamId(livestream.stream_id);
    setShowCreateLivestream(true);
  };

  const handleLicenseChange = (e) => {
    //write('userSelectedLicenseID', e.value);
    const selLicense = licenses.find((license) => license.value === e.value);
    const selLicenseStreamApp = selLicense.apps.find(
      (app) => app.platform === 'streaming'
    );
    if (selLicenseStreamApp !== undefined) {
      const header = { 'X-Api-Key': selLicenseStreamApp.api_keys[0].key };
      setRes(new Restful(process.env.REACT_APP_BACKEND_URL, header));
    }
    setSelectedLicense(selLicense);
  };

  useEffect(() => {
    if (selectedLicense) {
      refetch();
    }
  }, [res, refetch, selectedLicense]);

  function handleShowDeleteStream(stream) {
    setSelectedStream(stream);
    setShowDeleteLivestreamModal(true);
  }
  return (
    <>
      <DeleteStream
        usage={'livestream'}
        showDeleteStream={showDeleteLivestreamModal}
        handleCloseDeleteStream={() => setShowDeleteLivestreamModal(false)}
        selectedStream={selectedStream}
        deleteStream={mutateDeleteLivestream}
        isDeleting={isDeleting}
      />
      {showCreateLivestream ? (
        <CreateLivestream
          post={mutatePostLivestream}
          put={mutatePutLivestream}
          livestreamName={livestreamName}
          livestreamServices={livestreamServices}
          isEdit={editLivestreamId}
          selectedLicense={selectedLicense}
          header={selectedLicense.apps[0].api_keys[0].key}
        />
      ) : (
        <Container className="mt-5">
          <Row>
            <Col xs={12} sm={12} md={6}>
              <h1 className="heading-h1">Livestream</h1>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <div className="d-flex justify-content-md-end align-items-center">
                <div>License</div>
                <Col md={4}>
                  {' '}
                  <Select
                    styles={{ indicatorSeparator: () => {} }}
                    options={licenses}
                    value={selectedLicense}
                    isSearchable={false}
                    onChange={(e) => handleLicenseChange(e)}
                  />
                </Col>

                <OverlayTrigger placement="bottom" overlay={renderTooltip}>
                  <AiOutlineInfoCircle />
                </OverlayTrigger>

                <Button
                  variant="primary"
                  className="btn-create-livestream ml-3"
                  onClick={handleShowCreateLivestream}
                >
                  Create Livestream
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col style={{ fontSize: '15px', lineHeight: '24px' }}>
              You provide a RTMP input stream, Veeplay outputs HLS streams for
              live and VOD playback.
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={12}>
              {getLivetreamListSuccess && livestreamList.length > 0 ? (
                livestreamList.map((item, idx) => (
                  <LivestreamItem
                    item={item}
                    key={idx}
                    edit={handleShowEditLivestream}
                    livestreamAction={mutateLivestreamAction}
                    isStartStopLoading={
                      item.stream_id === livestreamID
                        ? isStartStopLoading
                        : null
                    }
                    handleShowDeleteStream={handleShowDeleteStream}
                  />
                ))
              ) : (
                <div className="no-livestream">No livestreams created yet.</div>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
Livestream.propTypes = {
  userSelectedLicense: PropTypes.any,
  xAPIkey: PropTypes.any,
};
export default Livestream;
