/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import {
  Row,
  Col,
  Container,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import ContentStream from '../components/ContentStream';
import PropTypes from 'prop-types';
import LogsModal from '../components/LogsModal';
import Restful from '../utils/API';
import { useQuery } from 'react-query';
import Toaster from '../components/Toaster';
import { MediaPlayer, MediaBuilder, MediaUnit } from 'veeplay';

const ServerSideAdInsertion = (props) => {
  const { elements } = props;
  const [showInputContentStream, setShowInputContentStream] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [showLinks, setShowLinks] = useState(true);
  const [showLogs, setShowLogs] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [hasDemoConfig, setHasDemoConfig] = useState(false);
  const [analyticsList, setAnalyticsList] = useState([]);
  const [logsList, setLogsList] = useState([]);
  const targetHLS = useRef(null);
  const targetDASH = useRef(null);
  const [showTooltipHLS, setShowTooltipHLS] = useState(false);
  const [showTooltipDASH, setShowTooltipDASH] = useState(false);
  const [ssaiID, setssaiID] = useState(null);
  const [ssaiURL, setssaiURL] = useState(null);
  const [logDetails, setLogDetails] = useState();
  const [ssaiLinks, setSsaiLinks] = useState([]);
  const videoElement = useRef(null);
  const [player, setPlayer] = useState(null);
  const [hlsURL, sethlsURL] = useState(null);
  const [dashURL, setdashURL] = useState(null);
  const handleShowInputContentStream = () => setShowInputContentStream(true);

  const handleShowLogsModal = (log) => {
    setLogDetails(log);
    setShowLogsModal(true);
  };
  const handleCloseLogsModal = () => setShowLogsModal(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const handleEditStream = () => {
    setIsUpdate(true);
    setShowInputContentStream(true);
  };

  useEffect(() => {
    if (videoElement.current && ssaiURL !== null) {
      const videoUrl = ssaiURL;
      const player = new MediaPlayer(videoElement.current.id);
      setPlayer(player);
      player.playMediaUnits([new MediaUnit(videoUrl)]);
      player.setMute(true);
    }
  }, [videoElement, ssaiURL]);

  const reloadContent = async (url) => {
    const builder = new MediaBuilder(player);
    await builder.configureFromJSON({
      content: [
        {
          url: url,
          autoplay: true,
          manager: 'veeplay-ssai-client',
        },
      ],
    });
    const units = await builder.mediaUnits();
    player.playMediaUnits(units);
    player.setMute();
  };

  const res = new Restful(process.env.REACT_APP_BACKEND_URL);

  const fetchSSAIConfig = async () => {
    return res.fetch('/api/ssai-configuration');
  };
  const fetchSSAIMetric = async () => {
    return res.fetch('/api/ssai-metrics');
  };
  const fetchSSAILogs = async () => {
    return res.fetch('/api/ssai-logs');
  };
  const { status: querySSAIConfig, data: userSSAIConfig } = useQuery(
    'userSSAIConfig',
    fetchSSAIConfig
  );
  useEffect(() => {
    (async (response) => {
      if (!response) {
        return;
      }
      if (response.url) {
        setssaiID(response.id);
        setssaiURL(response.url);
        setHasDemoConfig(true);
        sethlsURL(response.hls);
        setdashURL(response.dash);
        setSsaiLinks([
          {
            label: 'HLS',
            value: response.hls,
          },
          {
            label: 'DASH',
            value: response.dash,
          },
        ]);
      }
    })(userSSAIConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSSAIConfig]);
  const { data: userSSAIMetric } = useQuery('userSSAIMetric', fetchSSAIMetric);
  useEffect(() => {
    (async (response) => {
      if (!response) {
        return;
      }
      if (response.length > 0) {
        const analytics = response.map((metric) => ({
          label: metric.name,
          value: metric.value,
          description: metric.description,
        }));
        setAnalyticsList(analytics);
      }
    })(userSSAIMetric);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSSAIMetric]);
  const { data: userSSAILogs } = useQuery('userSSAILogs', fetchSSAILogs);
  useEffect(() => {
    (async (response) => {
      if (!response) {
        return;
      }
      if (response.logs.length > 0) {
        const logs = response.logs.map((event) => JSON.parse(event));
        setLogsList(logs);
      }
    })(userSSAILogs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSSAILogs]);

  const handleShowLinks = () => {
    setShowLinks(true);
    setShowLogs(false);
    setShowAnalytics(false);
  };
  const handleShowLogs = () => {
    setShowLinks(false);
    setShowLogs(true);
    setShowAnalytics(false);
  };
  const handleShowAnalytics = () => {
    setShowLinks(false);
    setShowLogs(false);
    setShowAnalytics(true);
  };

  const copyHLSValue = () => {
    navigator.clipboard.writeText(hlsURL);
    setShowTooltipHLS(true);
    setTimeout(() => setShowTooltipHLS(false), 3000);
  };
  const copyDASHValue = () => {
    navigator.clipboard.writeText(dashURL);
    setShowTooltipDASH(true);
    setTimeout(() => setShowTooltipDASH(false), 3000);
  };

  if (querySSAIConfig !== 'success') return <Container />;
  return (
    <div>
      {showInputContentStream ? (
        <ContentStream update={isUpdate} ssaiID={ssaiID} />
      ) : (
        <Container>
          <LogsModal
            showLogsModal={showLogsModal}
            handleCloseLogsModal={handleCloseLogsModal}
            logDetails={logDetails}
          />
          <React.Fragment>
            <Row className="mt-5">
              <Col xs={12} sm={12} md={6}>
                <h1 style={{ fontWeight: '700' }}>Server Side Ad Insertion</h1>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <div className="d-flex justify-content-center justify-content-md-end align-items-center">
                  <Button
                    variant="outline-secondary"
                    className="btn-ssai-docs"
                    href="https://docs.veeplay.com/docs/ssai/index/"
                    target="_blank"
                  >
                    View SSAI Docs
                  </Button>
                  <Button
                    variant="primary"
                    className="btn-sched-call ml-3"
                    href="https://calendly.com/veeplay"
                    target="_blank"
                    style={
                      elements || hasDemoConfig
                        ? { display: 'flex' }
                        : { display: 'none' }
                    }
                  >
                    Schedule a Call
                  </Button>
                </div>
              </Col>
            </Row>
            <Row
              className="mt-5"
              style={
                elements || hasDemoConfig
                  ? { display: 'none' }
                  : { display: 'flex' }
              }
            >
              <Col xs={12} sm={12} md={12}>
                <div
                  style={{
                    background: '#FCFCFC',
                    height: '398px',
                    borderRadius: '12px',
                    border: '1px solid #F0F0F0',
                  }}
                >
                  <div
                    style={{
                      height: '254px',
                      margin: '72px 230px auto',
                      textAlign: 'center',
                    }}
                  >
                    <img src="/assets/DemoStream.svg" alt="Demo Stream" />
                    <h3 style={{ marginTop: '40px', fontWeight: '600' }}>
                      Setup a Demo Stream
                    </h3>
                    <p style={{ marginTop: '16px' }}>
                      Server side ad insertion is a modern streaming technique
                      that minimizes buffering times and helps with avoiding
                      lost ad inventory to client-side ad blockers.
                    </p>
                    <Button
                      variant="primary"
                      className="btn-veeplay-blue"
                      style={{ marginTop: '15px', marginLeft: '0px' }}
                      onClick={handleShowInputContentStream}
                    >
                      Start Setup
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <hr
              className="divider-new"
              style={
                elements || hasDemoConfig
                  ? { display: 'flex' }
                  : { display: 'none' }
              }
            />
            <Row
              className="mt-5"
              style={
                elements || hasDemoConfig
                  ? { display: 'flex' }
                  : { display: 'none' }
              }
            >
              <Col md={6}>
                <div
                  id="player"
                  ref={videoElement}
                  className="w-100"
                  style={{ height: 300 }}
                />
                <div className="mt-2 d-flex justify-content-center justify-content-md-start">
                  <Button
                    variant="outline-secondary"
                    className="btn-edit-stream"
                    style={{ fontSize: '11px' }}
                    onClick={handleEditStream}
                  >
                    <img
                      src="/assets/icons/EditMini.svg"
                      alt="Edit Mini Icon"
                      style={{ margin: '-0.2em 0.7em auto -0.7em' }}
                    />
                    Edit Stream
                  </Button>
                  <Button
                    variant="outline-secondary"
                    className="btn-reload-content"
                    style={{ fontSize: '11px' }}
                    onClick={() => reloadContent(ssaiURL)}
                  >
                    <img
                      src="/assets/icons/Reload.svg"
                      alt="Reload Icon"
                      style={{ margin: '-0.2em 0.7em auto -0.7em' }}
                    />
                    Reload Content
                  </Button>
                </div>
              </Col>
              <Col md={6}>
                <Row className="mt-3 mt-md-0 align-items-center">
                  <Col md={3}>
                    <div className="ssai-details">Details:</div>
                  </Col>
                  <Col md={9}>
                    <div className="d-flex justify-content-between mt-3 mt-md-0">
                      <Button
                        variant="outline-secondary"
                        className="btn-links"
                        onClick={handleShowLinks}
                        active={showLinks}
                      >
                        LINKS
                      </Button>
                      <Button
                        variant="outline-secondary"
                        className="btn-logs"
                        onClick={handleShowLogs}
                        active={showLogs}
                      >
                        LOGS
                      </Button>
                      <Button
                        variant="outline-secondary"
                        className="btn-analytics"
                        onClick={handleShowAnalytics}
                        active={showAnalytics}
                      >
                        ANALYTICS
                      </Button>
                    </div>
                  </Col>
                </Row>
                {showLinks && (
                  <div className="mt-5">
                    {ssaiLinks.map((item, idx) => (
                      <Row className="mt-3" key={idx}>
                        <Col>
                          <div className="div-hls">
                            <p className="hls-label">{item.label}</p>
                            <div className="d-flex justify-content-between">
                              <div className="div-links">
                                <div className="div-text">{item.value}</div>
                              </div>
                              <div>
                                <Button
                                  className="gray-circle-btn"
                                  variant="light"
                                  style={{ marginTop: '5px' }}
                                  ref={
                                    item.label === 'HLS'
                                      ? targetHLS
                                      : targetDASH
                                  }
                                  onClick={
                                    item.label === 'HLS'
                                      ? copyHLSValue
                                      : copyDASHValue
                                  }
                                >
                                  <img
                                    src="/assets/icons/Copy.svg"
                                    alt="Copy Icon"
                                  />
                                </Button>
                              </div>
                              <Toaster
                                target={
                                  item.label === 'HLS'
                                    ? targetHLS.current
                                    : targetDASH.current
                                }
                                showTooltip={
                                  item.label === 'HLS'
                                    ? showTooltipHLS
                                    : showTooltipDASH
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </div>
                )}
                {showLogs ? (
                  <Row className="div-logs">
                    <Col>
                      <div className="log-list">
                        {logsList.map((log, idx) => (
                          <Row className="log-list-item mt-3" key={idx}>
                            <Col xs={4}>
                              <div>{log.eventDescription}</div>
                            </Col>
                            <Col xs={4}>
                              <img
                                src="/assets/icons/Alert.svg"
                                alt="Alert Icon"
                                onClick={() => handleShowLogsModal(log)}
                              />
                            </Col>
                            <Col xs={4}>
                              <p className="timestamp">{log.eventTimestamp}</p>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </Col>
                  </Row>
                ) : null}
                {showAnalytics ? (
                  <Row className="div-ssai-analytics">
                    <Col>
                      <div>
                        <p className="stats-7-days">
                          Statistics for the last 7 days:
                        </p>
                        <div className="analytics-list">
                          <Row>
                            {analyticsList.map((item, idx) => (
                              <Col md={6} key={`${item.label}-${item.value}`}>
                                <div>
                                  <p className="analytics-txt">{item.label}</p>
                                  <div className="div-value">
                                    <p className="analytics-val">
                                      {item.value}
                                    </p>
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id={idx}>
                                          {item.description}
                                        </Tooltip>
                                      }
                                    >
                                      <img
                                        src="/assets/icons/Alert-Normal.svg"
                                        alt="Alert Normal Icon"
                                        style={{ marginLeft: '8px' }}
                                        className="icon"
                                      />
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </Col>
                            ))}
                            <Col md={6}>
                              <div className="d-flex justify-content-md-end">
                                Data is updated hourly.
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
          </React.Fragment>
        </Container>
      )}
    </div>
  );
};
ServerSideAdInsertion.propTypes = {
  elements: PropTypes.any,
};
export default ServerSideAdInsertion;
