import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { IntercomProvider } from 'react-use-intercom';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './index.css';
import { version } from '../package.json';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://03158e1fca274bbabe5986fdecf2a43d@o970064.ingest.sentry.io/5921235',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    release: `veeplay-dashboard@${version}`,
    tracesSampleRate: 0.1,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      showDialog
      fallback={<p>An error has occurred. Please refresh.</p>}
    >
      <IntercomProvider appId={'hdsacrdm'} autoBoot>
        <App />
      </IntercomProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
