import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import ServerSideAdInsertion from '../pages/ServerSideAdInsertion';
import VastTag from './VastTag';
import validator from 'validator';
import PropTypes from 'prop-types';

const ContentStream = (props) => {
  const { contentUrl, update, ssaiID } = props;
  const [showNoDemoStream, setShowNoDemoStream] = useState(false);
  const [showInputVastTag, setShowInputVastTag] = useState(false);
  const [contentStreamUrl, setContentStreamUrl] = useState(contentUrl);
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const validateUrl = (value) => {
    return validator.isURL(value);
  };

  const handleShowNoDemoStream = () => {
    setShowNoDemoStream(true);
  };
  const handleShowInputVastTag = () => {
    setShowInputVastTag(true);
  };
  useEffect(() => {
    if (contentStreamUrl) {
      const isValidUrl = validateUrl(contentStreamUrl);
      setIsNextDisabled(!isValidUrl);
    }
  }, [contentStreamUrl]);
  return (
    <div>
      {showInputVastTag ? (
        <VastTag
          contentUrl={contentStreamUrl}
          update={update}
          ssaiID={ssaiID}
        />
      ) : showNoDemoStream ? (
        <ServerSideAdInsertion elements={update} />
      ) : (
        <Container>
          <React.Fragment>
            <Row className="mt-5">
              <Col md={7}>
                <h1>
                  <span style={{ fontWeight: '700' }}>Step 1</span> - Input a
                  Content Stream
                </h1>
              </Col>
              <Col md={5} style={{ textAlign: 'right' }}>
                <Button
                  variant="outline-secondary"
                  className="btn-pale-grey"
                  onClick={handleShowNoDemoStream}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-veeplay-blue ml-3"
                  onClick={handleShowInputVastTag}
                  disabled={isNextDisabled}
                >
                  Next Step
                </Button>
              </Col>
            </Row>
            <hr className="divider"></hr>
            <Row className="mt-5">
              <Col md={6}>
                <p className="url-label">
                  Enter the URL of the publicly-available HLS or DASH content
                  stream (m3u8/mpd):
                </p>
              </Col>
              <Col md={6}>
                <input
                  placeholder="Enter URL"
                  onChange={(e) => setContentStreamUrl(e.target.value)}
                  value={contentStreamUrl}
                  className="input-field"
                ></input>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={6}>
                <p className="list-label">USING HLS?</p>
                <div>
                  <div className="blue-bullet"></div>
                  <div className="list-item-details">
                    Manifests must be live or video-on-demand (VOD).
                  </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <div className="blue-bullet"></div>
                  <div className="list-item-details">
                    Manifests must have an EXT-X-VERSION of 3 or higher.
                  </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <div className="blue-bullet"></div>
                  <div className="list-item-details">
                    For live content, manifests must contain markers to
                    delineate ad avails.
                  </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <div className="blue-bullet"></div>
                  <div className="list-item-details">
                    HLS master manifests must follow the HLS specification
                    documented at HTTP Live Streaming: Master Playlist Tags.
                    <br />
                    <br />
                    In particular, #EXT-X-STREAM-INF must include the fields
                    RESOLUTION, BANDWIDTH, and CODEC.
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <p className="list-label">USING DASH?</p>
                <div>
                  <div className="blue-bullet"></div>
                  <div className="list-item-details">
                    Manifests must be live or video-on-demand (VOD).
                  </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <div className="blue-bullet"></div>
                  <div className="list-item-details">
                    Manifests must mark events as ad avails using either splice
                    insert markers or time signal markers. You can provide the
                    ad markers in clear XML or in base64-encoded binary. 
                  </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <div className="blue-bullet"></div>
                  <div className="list-item-details">
                    Ad avails must have the same AdaptationSet and
                    Representation settings as content streams. 
                  </div>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        </Container>
      )}
    </div>
  );
};
ContentStream.propTypes = {
  update: PropTypes.any,
  contentUrl: PropTypes.any,
  ssaiID: PropTypes.any,
};
export default ContentStream;
