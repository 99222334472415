/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/display-name */
// TODO: fix eslint warnings

import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col, Table, Form } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import Select from 'react-select';
import { addDays, format, parse } from 'date-fns';
import { useQuery } from 'react-query';
import Restful from '../utils/API';
import MockDropdown from '../components/MockDropdown';

const Usage = () => {
  const res = new Restful();
  const [playerData, setPlayerData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [licenseCode, setLicenseCode] = useState('');
  const [showPlayer, setShowPlayer] = useState(true);
  const handleshowPlayer = () => {
    setShowPlayer(true);
    setshowVideoAPI(false);
  };
  const [showVideoAPI, setshowVideoAPI] = useState(false);
  const handleshowVideoAPI = () => {
    setShowPlayer(false);
    setshowVideoAPI(true);
  };
  const [interval, setInterval] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [userApps, setUserApps] = useState([]);
  const [usageTypeDisplay, setUsageTypeDisplay] = useState({
    usage_type_display: 'overall',
  });
  const [licenses, setLicenses] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [selectedApp, setSelectedApp] = useState(null);

  const fetchApps = async () => {
    return res.fetch('/api/licenses');
  };

  const fetchPlayerUsage = async () => {
    usageTypeDisplay.period = formatPeriodParam(
      interval[0].startDate,
      interval[0].endDate
    );
    Object.keys(usageTypeDisplay).forEach((key) =>
      usageTypeDisplay[key] === undefined ? delete usageTypeDisplay[key] : {}
    );
    return res.fetch('/api/player-usage', usageTypeDisplay);
  };

  const fetchAPIUsage = async () => {
    return res.fetch('/api/api-usage');
  };

  function mapRegisteredApps(apps) {
    const registeredApps = [
      {
        value: { platform: 'all_apps', id: undefined },
        label: 'All apps',
      },
    ];
    apps.forEach((app) => {
      if (app.name !== 'streaming') {
        registeredApps.push({
          value: { platform: app.platform, id: app.id.toString() },
          label: app.name,
        });
      }
      setUserApps(registeredApps);
    });
    setSelectedApp(registeredApps[0]);
  }
  function mapPlayerData(data) {
    const days = data.graph.map((record) => [
      parse(record.day, 'dd/MM/yyyy', new Date()),
    ]);
    setPlayerData([
      {
        name: 'videos',
        data: data.graph.map((record, index) => [days[index], record.videos]),
      },
      {
        name: 'ads',
        data: data.graph.map((record, index) => [days[index], record.ads]),
      },
      {
        name: 'total',
        data: data.graph.map((record, index) => [days[index], record.total]),
      },
    ]);
  }
  function formatPeriodParam(startDate, endDate) {
    const start = format(startDate, 'dd/MM/yyyy');
    const end = format(endDate, 'dd/MM/yyyy');
    return `${start} - ${end}`;
  }

  const { data: playerStats, isSuccess: playerStatsSuccess } = useQuery(
    ['playerUsage', usageTypeDisplay, interval],
    fetchPlayerUsage
  );

  useEffect(() => {
    (async (response) => {
      if (!response) {
        return;
      }
      mapPlayerData(response.data);
    })(playerStats);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerStats]);

  const { isSuccess: apiDataStatus, data: apiUsage } = useQuery(
    'apiUsage',
    fetchAPIUsage
  );

  useEffect(() => {
    (async (data) => {
      if (!data) {
        return;
      }
      const transformedData = [];
      data.forEach((item) => {
        const d = new Date(item.start_date);
        const month = d.toLocaleString('default', { month: 'long' });
        transformedData.push({
          date: `${month} ${d.getFullYear()}`,
          bytes: item.bytes_transferred,
          duration: item.encoded_duration,
          asset_count: item.asset_count,
        });
      });
      setApiData(transformedData);
    })(apiUsage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUsage]);

  const { data: license } = useQuery('licenses', fetchApps);

  useEffect(() => {
    (async (data) => {
      if (!data) {
        return;
      }
      const mappedLicenses = data.map((license) => ({
        label: license.license_type.name,
        license_type_id: license.license_type_id,
        value: license.id,
        info: {
          code: license.code,
          valid_until: license.valid_until,
          appsLength: license.registered_apps.length,
          platforms: [
            ...new Set(license.registered_apps.map((app) => app.platform)),
          ],
        },
        apps: license['registered_apps'],
      }));
      setLicenses(mappedLicenses);
      let selectedLicense = mappedLicenses.find(
        (license) => license.license_type_id !== 4
      );

      if (selectedLicense === undefined) {
        selectedLicense = mappedLicenses[0];
      }
      setLicenseCode(selectedLicense.info['code']);
      mapRegisteredApps(selectedLicense['apps']);
      setSelectedLicense(selectedLicense);
    })(license);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [license]);

  const handleLicenseChange = (e) => {
    const selLicense = licenses.find((license) => license.value === e.value);
    setSelectedLicense(selLicense);
    setLicenseCode(selLicense.info['code']);
    mapRegisteredApps(selLicense['apps']);

    setUsageTypeDisplay({
      usage_type_display: 'license',
      entity_id: e.value,
    });
  };

  const handleAppChange = (e) => {
    const selApp = userApps.find((app) => app.value === e.value);
    setSelectedApp(selApp);

    setUsageTypeDisplay({
      usage_type_display: 'app',
      entity_id: e.value.id,
    });
  };

  const dropdownWithIcon = ({ value, label }) => (
    <div className="d-flex justify-content-between align-items-center">
      <div>
        <img
          src={
            value.platform === 'ios'
              ? '/assets/icons/ios-icon.svg'
              : value.platform === 'android'
              ? '/assets/icons/android-icon.svg'
              : value.platform === 'web'
              ? '/assets/icons/js-icon.svg'
              : '/assets/icons/all-apps-icon.svg'
          }
          alt="icon"
        />
        <label className="pl-3 mb-0" htmlFor="flexCheckDefault">
          {label}
        </label>
      </div>
    </div>
  );

  const chartOptions = {
    chart: {
      id: 'player-usage',
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
    },
    stroke: { curve: 'smooth' },
    legend: { position: 'bottom', horizontalAlign: 'left' },
    colors: ['#0095FF', '#FF6F43', '#DB4437'],
  };

  return (
    <Container>
      <Container className="mt-5">
        <Row>
          <Col>
            <h1 style={{ fontWeight: '700' }}>Usage</h1>
          </Col>
          <Col>
            <Row>
              <Col>
                <Button
                  variant="outline-secondary"
                  block
                  className={showPlayer && 'btn-usage'}
                  onClick={handleshowPlayer}
                >
                  Player
                </Button>
              </Col>
              <Col>
                <Button
                  variant="outline-secondary"
                  className={showVideoAPI && 'btn-usage'}
                  block
                  onClick={handleshowVideoAPI}
                >
                  Video API
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <hr className="mt-5 mb-4" />
      {showPlayer ? (
        <>
          <Container>
            <p
              style={{ fontWeight: '700', fontSize: '24px', color: '#0095FF' }}
            >
              Player Stats
            </p>
          </Container>
          <Container className="mt-4">
            <Form.Row>
              <Col className="pr-5">
                <Form.Group>
                  <Form.Label>Select Interval</Form.Label>
                  <MockDropdown
                    usage={'date-range-picker'}
                    interval={interval}
                    setInterval={setInterval}
                    formatDate={formatPeriodParam}
                  />
                </Form.Group>
              </Col>
              <Col className="pr-5">
                <Form.Group>
                  <Form.Label>Select License</Form.Label>
                  <Select
                    styles={{ indicatorSeparator: () => {} }}
                    options={licenses}
                    value={selectedLicense}
                    isSearchable={false}
                    onChange={(e) => handleLicenseChange(e)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Select App</Form.Label>
                  <Select
                    styles={{ indicatorSeparator: () => {} }}
                    options={userApps}
                    value={selectedApp}
                    isSearchable={false}
                    formatOptionLabel={dropdownWithIcon}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    onChange={(e) => handleAppChange(e)}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </Container>

          <Container>
            <Row className="align-items-center mt-4">
              <Col>
                <h4
                  style={{
                    fontWeight: '600',
                    fontSize: '24px',
                    color: '#002641',
                  }}
                >
                  Impressions
                </h4>
              </Col>
              <Col md="auto">
                <Row>
                  <Row className="align-items-center">
                    <span
                      style={{
                        color: '#0095FF',
                        fontWeight: '700',
                        fontSize: '32px',
                        marginRight: '24px',
                      }}
                    >
                      {playerStatsSuccess && playerStats.data.videos}
                    </span>
                    <h5
                      className="text-center"
                      style={{
                        fontWeight: '600',
                        fontSize: '12px',
                        color: '#969696',
                        marginRight: '40px',
                        marginBottom: '0px',
                      }}
                    >
                      VIDEOS PLAYED
                    </h5>
                  </Row>
                  <Row className="align-items-center">
                    <span
                      style={{
                        color: '#FF6F43',
                        fontWeight: '700',
                        fontSize: '32px',
                        marginRight: '24px',
                      }}
                    >
                      {playerStatsSuccess && playerStats.data.ads}
                    </span>
                    <h5
                      style={{
                        fontWeight: '600',
                        fontSize: '12px',
                        color: '#969696',
                        marginBottom: '0px',
                        marginRight: '40px',
                      }}
                    >
                      ADS DELIVERED
                    </h5>
                  </Row>
                </Row>
              </Col>
            </Row>
          </Container>
          <Chart options={chartOptions} series={playerData} />
        </>
      ) : (
        <>
          <Container>
            <p
              style={{ fontWeight: '700', fontSize: '24px', color: '#0095FF' }}
            >
              Video API Stats
            </p>
          </Container>
          <Container className="mt-4">
            {apiDataStatus ? (
              [
                apiData.length > 0 ? (
                  <Table key="hasApiData" borderless>
                    <thead
                      style={{
                        borderBottomStyle: 'solid',
                      }}
                    >
                      <tr>
                        <th>Month</th>
                        <th>License code</th>
                        <th>Stored assets</th>
                        <th>Encoded seconds</th>
                        <th>Transferred bytes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {apiData.map((item, idx) => (
                        <tr key={`${licenseCode}-${idx}`}>
                          <td>{item.date}</td>
                          <td>{licenseCode}</td>
                          <td> {item.asset_count}</td>
                          <td>{item.duration}</td>
                          <td>{item.bytes === null ? '-' : item.bytes}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div>No data retrieved.</div>
                ),
              ]
            ) : (
              <Container />
            )}
          </Container>
        </>
      )}
    </Container>
  );
};

export default Usage;
