import Restful from '../utils/API';
import React, { useState } from 'react';
import { Container, Button, Row, Col, Form, Alert } from 'react-bootstrap';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';

const AccountSettings = () => {
  const queryClient = useQueryClient();
  const res = new Restful(process.env.REACT_APP_BACKEND_URL);
  const [showResponse, setShowResponse] = useState(false);

  const fetchUserInformation = async () => {
    return res.fetch('/api/user');
  };

  const { register, handleSubmit } = useForm();
  const { data, status } = useQuery('userInformation', fetchUserInformation);

  const updateUser = async (data) => {
    return res.update('/api/user', data);
  };

  const {
    mutate,
    isSuccess,
    data: updateResponse,
  } = useMutation(updateUser, {
    onSuccess: async (data) => {
      await queryClient.refetchQueries('userInformation');
      setShowResponse(true);
    },
  });

  const onSubmit = (data) => {
    data.name = `${data.first_name} ${data.last_name}`;
    mutate(data);
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {status === 'success' && (
        <Container>
          <Row>
            <Col>
              <h1 className="mt-5" style={{ fontWeight: '700' }}>
                Account settings
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              {isSuccess && (
                <Alert
                  className="mt-4"
                  show={showResponse}
                  onClose={() => setShowResponse(false)}
                  variant={updateResponse.ok === true ? 'success' : 'danger'}
                  dismissible
                >
                  <Alert.Heading>
                    {updateResponse.ok === true
                      ? 'Success'
                      : 'Failed to update'}
                  </Alert.Heading>
                  <p>
                    {updateResponse.ok === true
                      ? 'User successfully updated.'
                      : updateResponse.error}
                  </p>
                </Alert>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <p className="account-settings-form-title">USER DETAILS</p>
              <Form.Group className="account-settings-form">
                <Form.Label className="account-settings-form">Email</Form.Label>
                <Form.Control
                  disabled
                  type="email"
                  placeholder="Enter email"
                  {...register('email', {
                    required: true,
                    value: data.email,
                  })}
                />
              </Form.Group>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label className="account-settings-form">
                      First name
                    </Form.Label>
                    <Form.Control
                      placeholder="First name"
                      {...register('first_name', {
                        value: data.name.split(' ')[0],
                      })}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className="account-settings-form">
                      Last name{' '}
                    </Form.Label>
                    <Form.Control
                      placeholder="Last name"
                      {...register('last_name', {
                        value: data.name.split(' ')[1],
                      })}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <p className="account-settings-form-title">CHANGE PASSWORD</p>
              <Form.Group className="account-settings-form">
                <Form.Label className="account-settings-form">
                  New password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="New password"
                  {...register('password', {})}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="account-settings-form">
                  Confirm new password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm new password"
                  {...register('conf_password', {})}
                />
                <Form.Text className="text-muted account-settings-helper-text">
                  * For security reasons, you will be signed-out upon
                  sucessfully changing your password. You can log in using your
                  new credentials.
                </Form.Text>
              </Form.Group>
            </Col>
            <Col md={6}>
              <p className="account-settings-form-title">COMPANY DETAILS</p>
              <Form.Group className="account-settings-form">
                <Form.Label className="account-settings-form">
                  Company name
                </Form.Label>
                <Form.Control
                  placeholder="Company name"
                  {...register('company', {
                    value: data.company,
                  })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="account-settings-form">
                  Company info
                </Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ minHeight: '104px' }}
                  placeholder="Enter company info"
                  {...register('company_details', {
                    value:
                      data.company_details === null ? '' : data.company_details,
                  })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="primary"
                type="submit"
                className="btn-account-settings"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </Form>
  );
};

export default AccountSettings;
