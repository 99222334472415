import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, Row, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const [showBanner, setShowBanner] = useState(true);
  const toggleShowBanner = () => setShowBanner(!showBanner);
  return (
    <>
      {showBanner === true && (
        <Row className="justify-content-center px-md-5 py-md-2 mt-2 mx-0 w-100 align-items-center bg-light">
          <span
            className="text-center align-middle"
            style={{ paddingRight: '10px' }}
          >
            Welcome to the new Veeplay dashboard.{' '}
            <span>
              <a href="https://panel.veeplay.com/resources">
                Switch back to the old version.
              </a>
            </span>
          </span>
          <Button
            variant="light"
            className="gray-circle-btn"
            onClick={toggleShowBanner}
          >
            <img src="/assets/icons/close-icon.svg" alt="Close Icon" />
          </Button>
        </Row>
      )}
      <Navbar
        bg="transparent"
        expand="lg"
        className="pl-md-5 pr-md-5 mt-3 w-100"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand href="/">
          <img
            src="/assets/icons/VeeplayLogo.svg"
            width="120"
            height="28"
            className="d-inline-block align-top"
            alt="Veeplay logo"
          />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavLink
              exact
              className="nav-link ml-md-3 ml-lg-5 p-2"
              to="/"
              activeClassName="selected-nav"
            >
              Video APIs
            </NavLink>
            <NavLink
              exact
              className="nav-link ml-md-3 ml-lg-5 p-2"
              to="/transcoding"
              activeClassName="selected-nav"
            >
              Transcoding
            </NavLink>
            <NavLink
              exact
              className="nav-link ml-md-3 ml-lg-5 p-2"
              to="/livestream"
              activeClassName="selected-nav"
            >
              Livestream
            </NavLink>
            <NavLink
              exact
              className="nav-link ml-md-3 ml-lg-5 p-2"
              to="/video-player"
              activeClassName="selected-nav"
            >
              Video Player
            </NavLink>
            <NavLink
              exact
              className="nav-link ml-md-3 ml-lg-5 p-2"
              to="/ssai"
              activeClassName="selected-nav"
            >
              SSAI
            </NavLink>
          </Nav>
        </Navbar.Collapse>
        <NavLink
          exact
          className="navbar-text ml-2 px-2 nav-usage"
          to="/usage"
          activeClassName="selected-nav"
        >
          <img
            src="/assets/icons/Usage.svg"
            width="24"
            height="24"
            className="d-inline-block align-top"
            alt="Usage logo"
          />{' '}
          Usage
        </NavLink>

        <NavDropdown
          className="ml-lg-5"
          alignRight
          title={
            <span>
              <img
                src="/assets/icons/Profile.svg"
                className="d-inline-block align-top"
                alt="User logo"
              />
            </span>
          }
        >
          <NavDropdown.Item href="/account-settings">
            Account settings
          </NavDropdown.Item>
          <NavDropdown.Item href="/logout">Sign Out</NavDropdown.Item>
        </NavDropdown>
      </Navbar>
    </>
  );
};

export default Header;
